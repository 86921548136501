header.profile-header-v2 {
  font-family: ProximaNova;
  background-color: #FBFCFE;
  border: 1px solid rgba(230, 233, 237, 0.8);
  border-top: 12px solid #192F48;
  height: 107px;
  max-height: 107px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  color: #333132;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-left: 50px;
  .business-header-info {
    display: flex;
    align-items: center;
  }
  .title {
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333132;
    font-size: 25px;
    font-weight: 400;
    line-height: 27px;
    margin-left: 0;
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #D7DEE3;
  }
  .business-card-address {
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    a:link, a:visited {
      color: #333132;
    }
    a:hover {
      color: #3965ce;
      text-decoration: underline;
    }
  }
  .business-card-contact {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0;
    list-style: none;
    li.desktop-v2 {
      padding-left: 10px;
      max-width: 165px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      a:link, a:visited {
        color: #333132;
      }
      a:hover {
        color: #3965ce;
        text-decoration: underline;
      }
      a {
        img {
          width: 38px;
          height: 38px;
        }
      }
    }
    li.desktop-v2:before {
      content:"\00b7";
      padding-right: 10px;
      font-weight: bolder;
      color: #333132;
      font-size: 14px;
    }
    li.tablet-view-v2 {
      display: none;
    }
    .tablet-view-v2.contact-phone {
      order: -1
    }
    .tablet-view-v2.website-url {
      order: 3
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  header.profile-header-v2 {
    .title {
      font-size: 20px;
      max-width: 220px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .business-card-address {
      max-width: 200px;
      font-size: 13px;
    }
    .business-card-contact {
      justify-content: flex-end;
      margin-right: 50px;
      li.desktop-v2 {
        display: none;
      }
      li.tablet-view-v2 {
        display: block;
        margin-left: 30px;
      }
    }
  }
}

@media (min-width: 601px) and (max-width: 767px) {
  header.profile-header-v2 {
    padding-left: 42px;
    .title {
      font-size: 12px;
      max-width: 114px;
      margin-right: 7px;
      padding-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .business-card-address {
      font-size: 12px;
      max-width: 170px;
    }
    .business-card-contact {
      flex-flow: row nowrap;
      justify-content: flex-end;
      margin-right: 50px;
      li.desktop-v2 {
        display: none;
      }
      li.tablet-view-v2 {
        display: block;
        margin-left: 30px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  header.profile-header-v2 {
    flex-flow: column nowrap;
    padding-left: 0;
    .business-header-info {
      height: 40px;
    }
    .title {
      font-size: 12px;
      max-width: 114px;
      margin-right: 7px;
      padding-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .business-card-address {
      font-size: 12px;
      max-width: 170px;
    }
    .business-card-contact {
      margin-right: 30px;
      li.desktop-v2 {
        display: none;
      }
      li.tablet-view-v2 {
        display: block;
        margin-left: 30px;
      }
    }
  }
}