// Styling for business profile scheduling page
//
// This is one big hack around the D3CP scheduling widget, and if you ever have
// to maintain this … sorry.
.business-profile-schedule {
  .business-profile-schedule-widget {
    overflow: hidden;

    @media @mobile {
      width: 320px;
      float: none;
    }
  }
}

div.appointments-disabled {
  p {
    padding: 10px;
    margin: 5% auto;
    text-align: center;
    &.message {
      padding: 20px;
      background-color: #D4D9DC;
      font-size: 2.6 * @rem;
      line-height: 1.2;
    }
  }
}

#D3cpWidget {
  width: 100%;
  padding: 20px;
}
.d3cp_form_row {
  display: block;
  margin: 0 0 18px;
  &.highlight{
    background-color: #FFFEDC;
    padding: 0 0 5px;
    .smallblack {
      color: #555;
      font-size: 90%;
    }
  }
}
.d3cp_form_title {
  // Derived from Harmony label styling
  .helvetica-bold;
  display: block;
  margin-bottom: 4px;
  padding-left: 2px;


  &.forComment{
    //line-height: 21px;
  }
  &.d3cp_premium{
    //text-align: center;
  }
  &.d3cp_marked {
    //background: url(//www.demandforce.com/widget/css/images/d3cp_mark_red.png) no-repeat scroll left 8px transparent;
  }
}
.d3cp_input_outer {
  display: inline-block;
  @media @mobile {
    display: block;
  }
}
.d3cp_input_wrap {
  display: inline-block;
}
.d3cp_form_exp {
  // Derived from Harmony placeholder styling
  font-style: italic;
  // For comments, exp is contained inside title, reset title styling
  .helvetica-regular;
  margin-bottom: 0;
  padding-left: 0;
  
  
  &.d3cp_marked {
  }
}
.d3cp_input_name {
  width: 140px;
}
.d3cp_input_normal {
  width: 280px;
}
.d3cp_scheduler_wrap {
  display: inline-block;
}
.d3cp_input_date {
  background: url(//www.demandforce.com/widget/css/images/d3cp_calendar.gif) no-repeat scroll 112px 3px;
  width: 140px;
}
.d3cp_input_median {
  width: 140px;
}
.d3cp_select_timeofday {
  width: 140px;
}
.d3cp_input_long {
  width: 400px;
  @media @mobile {
    width: 280px;
  }
}
.d3cp_input_short {
  width: 140px;
}
.d3cp_input_captcha {
  letter-spacing: 5px;
  width: 140px;
}
.d3cp_select_year {
  width: 100px;
}
.d3cp_select_modelmake {
  width: 140px;
}
.d3cp_select_model {
  width: 140px;
}
.d3cp_select_apptService {
  .d3cp_input_long;
}
.d3cp_textarea {
  height: 140px;
}
.d3cp_captcha_wrap {
  display: inline-block;
  padding: 4px;
}
.d3cp_captcha_img {
  border: 1px solid #020202;
  margin-bottom: -5px;
}
.d3cp_captcha_change {
  color: @link-color;
  display: block;
}
/*
.d3cp_form_row.d3cp_form_single {
  display: inline-block;
  margin-left: 195px;
  margin-top: 2px;
  padding: 10px 0 0;
  @media @mobile {
    margin-left: 0;
  }
}
*/



.d3cp_button_submit {
  background: url(//www.demandforce.com/widget/css/images/d3cp_button_submit_mid.png) no-repeat scroll 0 0 transparent;
}
.d3cp_form_single .d3cp_button_submit {
  margin: 10px 0 15px;
}
.d3cp_button_submit.delete {
  background: url(//www.demandforce.com/widget/css/images/d3cp_button_delete_mid.png) no-repeat scroll 0 0 transparent;
}
.d3cp_button_submit.next {
  background: url(//www.demandforce.com/widget/css/images/d3cp_button_next_mid.png) no-repeat scroll 0 0 transparent;
}
.d3cp_button_submit.preview {
  background: url(//www.demandforce.com/widget/css/images/d3cp_button_preview_mid.png) no-repeat scroll 0 0 transparent;
}
.d3cp_button_submit.unsubscribe {
  background: url(//www.demandforce.com/widget/css/images/d3cp_button_unsubscribe_mid.png) no-repeat scroll 0 0 transparent;
}
.d3cp_form_comment {
  clear: both;
  .helvetica-light;
  line-height: 1.4;
}
.d3cp_button_mid {
  height: 30px;
  width: 153px;
  float: left;
}
.d3cp_form_single .d3cp_bttns_inline .d3cp_button_submit {
  /*
  margin: 0 18px 0 0;
  */
}
.d3cp_df_seal_widget {
  display: none; // Don't show on scheduling page
}
.d3cp_clear {
  clear: both; height: 0; line-height: 0; overflow: hidden;
}


.d3cp_form_detail {
  //display: inline-block; font-size: 16px; line-height: 34px; padding-right: 7px;
}
.d3cp_form_detail .d3cp_premium {
  //color: #0066AA; display: inline-block; font-weight: normal; line-height: 18px; word-wrap: normal;  
}
.d3cp_form_type_bar .comments {
   //color: #c87e1f; float: right; font-size: 12px; font-style: italic; font-weight: normal;
}

.d3cp_des {
  font-size: 16px; line-height: 1.25em; margin-bottom: 20px;
  &.d3cp_des_result {
    color: #7E7E7E;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
  }
}
.d3cp_confirmation {
  margin: 10px 20px;
}
.d3cp_des_result_row {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  margin: 5px 0;
}
.d3cp_des_row_title {
  color: #0066aa;
  display: block;
  float: left;
  font-weight: bold;
  margin: 0 5px 0 0;
  padding-left: 10px;
  width: 190px;
}
.d3cp_des_row_detail {
  display: block;
  float: left;
  font-weight: normal;
  padding-right: 7px;
  width: 340px;
}


/*
 * ------
 * JS Calendar CSS Style
 * ------
 */
.D3cpDynarchCalendar-topCont {
  width: 280px;
  padding: 0;

  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  line-height: 28px;

  background: @background-color;
  border: 1px solid @border-color;
  .box-shadow(2px 2px 8px 2px @border-color);

  .D3cpDynarchCalendar {
    position: relative;
    width: 280px;
    margin: 0;

  }

  table {
    border-collapse: collapse;
    line-height: 14px;
    margin: auto;
  }

  // Harmony has table styles, discard them.
  &, table {
    width: auto;
    tr td {
      height: inherit;
      padding: 0;
      border: none;
    }
    tr:hover {
      background-color: transparent;
    }
  }
}

.D3cpDynarchCalendar-topBar {
  position: relative;
}
table.D3cpDynarchCalendar-titleCont {
  width: 100%;
  background: @slate;
  color: @background-color;
  .helvetica-bold;
  text-align: center;
  z-index: 9;
}

.D3cpDynarchCalendar-title div {
  margin-top: 20px;
  line-height: 35px;
  text-align: center;
}
.D3cpDynarchCalendar-hover-title div {
  background-image: url(//www.demandforce.com/widget/css/images/js-cal-img/drop-down.gif);
  background-repeat: no-repeat;
  background-position: 100% 50%;
}
.D3cpDynarchCalendar-pressed-title div {
  background-image: url(//www.demandforce.com/widget/css/images/js-cal-img/drop-up.gif);
  background-repeat: no-repeat;
  background-position: 100% 50%;
}
.D3cpDynarchCalendar-first-col div {
  //padding-left: 5px;
}
.D3cpDynarchCalendar-last-col div {
  //padding-right: 5px;
}
.D3cpDynarchCalendar-animBody-backYear {
  position: absolute;
  top: -100%;
  left: 0;
}
.D3cpDynarchCalendar-animBody-back {
  position: absolute;
  top: 5px;
  left: -100%;
}
.D3cpDynarchCalendar-animBody-fwd {
  position: absolute;
  top: 5px;
  left: 100%;
}
.D3cpDynarchCalendar-animBody-now {
  position: absolute;
  top: 5px;
  left: 0;
}
.D3cpDynarchCalendar-animBody-fwdYear {
  position: absolute;
  top: 100%;
  left: 0;
}
.D3cpDynarchCalendar-navBtn {
  position: absolute;
  top: 5px;
  //z-index: 10;
}
.D3cpDynarchCalendar-navBtn div {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 15px;
  width: 16px;
  padding: 1px;
}
.D3cpDynarchCalendar-navDisabled {
  .opacity(0.3);
}
.D3cpDynarchCalendar-prevMonth {
  left: 25px;
}
.D3cpDynarchCalendar-nextMonth {
  left: 100%;
  margin-left: -43px;
}
.D3cpDynarchCalendar-prevYear {
  left: 5px;
}
.D3cpDynarchCalendar-nextYear {
  left: 100%;
  margin-left: -23px;
}
.D3cpDynarchCalendar-prevMonth div {
  background-image: url(//www.demandforce.com/widget/css/images/js-cal-img/nav-left.gif);
}
.D3cpDynarchCalendar-nextMonth div {
  background-image: url(//www.demandforce.com/widget/css/images/js-cal-img/nav-right.gif);
}
.D3cpDynarchCalendar-prevYear div {
  background-image: url(//www.demandforce.com/widget/css/images/js-cal-img/nav-left-x2.gif);
}
.D3cpDynarchCalendar-nextYear div {
  background-image: url(//www.demandforce.com/widget/css/images/js-cal-img/nav-right-x2.gif);
}


.D3cpDynarchCalendar-hover-navBtn,
.D3cpDynarchCalendar-pressed-navBtn {
  background: @slate;
  color: white;
}

.D3cpDynarchCalendar-menu {
  position: absolute;
  left: 0;
  top: 54px;
  width: 100%;
  height: 312px;
  overflow: hidden;
  background: @background-color;
  .opacity(0.9);

}
.D3cpDynarchCalendar-menu td {
  height: 48px;
  padding: 0;
  margin: 0;
  .helvetica-bold;
  div {
    line-height: 48px;
    text-align: center;
    vertical-align: center;
  }
  width: 280px;
}
.D3cpDynarchCalendar-menu-mtable td {
  width: 92px;
}

.D3cpDynarchCalendar-menu-year {
  text-align: center;
  margin: 0 auto;
  display: block;
  .helvetica-bold;
}
.D3cpDynarchCalendar-menu-sep {
  display: none;
}
/*
.D3cpDynarchCalendar-time td {
  .helvetica-bold;
}
.D3cpDynarchCalendar-time-hour,
.D3cpDynarchCalendar-time-minute {
  padding: 1px 3px;
}
.D3cpDynarchCalendar-time-down {
  background: url(//www.demandforce.com/widget/css/images/js-cal-img/time-down.png) no-repeat 50% 50%;
  width: 11px;
  height: 8px;
  .opacity(0.5);
}
.D3cpDynarchCalendar-time-up {
  background: url(//www.demandforce.com/widget/css/images/js-cal-img/time-up.png) no-repeat 50% 50%;
  width: 11px;
  height: 8px;
  .opacity(0.5);
}
.D3cpDynarchCalendar-time-sep {
  padding: 0 2px;
}
.D3cpDynarchCalendar-hover-time {
  background-color: #444;
  color: #fff;
  .opacity(1);
}
.D3cpDynarchCalendar-pressed-time {
  background-color: #000;
  color: #fff;
  .opacity(1);
}
.D3cpDynarchCalendar-time-am {
  padding: 1px;
  width: 2.5em;
  text-align: center;
  .border-radius(3px);
}
.D3cpDynarchCalendar-weekNumber {
  border-right: 1px solid @border-color;
  margin-right: 4px;
  width: 2em !important;
  padding-right: 8px !important;
}
.D3cpDynarchCalendar-day-othermonth.D3cpDynarchCalendar-hover-date {
  border-color: #aaa;
  color: #888;
}
*/
/* focus */
.D3cpDynarchCalendar-focusLink {
  position: absolute;
  .opacity(0);
}
.D3cpDynarchCalendar-tooltip {
  position: absolute;
  top: 100%;
  width: 100%;
}
.D3cpDynarchCalendar-tooltipCont {
  margin: 0 5px 0 5px;
  border: 1px solid #aaa;
  border-top: 0;
  padding: 3px 6px;
  background: #ddd;
}




.D3cpDynarchCalendar-body {
  position: relative;
  overflow: hidden;
}
.D3cpDynarchCalendar-dayNames td,
.D3cpDynarchCalendar-week td,
div.D3cpDynarchCalendar-day,
.D3cpDynarchCalendar-weekNumber {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: center;
  padding: 0;
  margin: 0;
  border: none;
}
.D3cpDynarchCalendar-dayNames {
  background: @light-slate;
  border-bottom: 1px solid @border-color;
}
div.D3cpDynarchCalendar-day {
}
.D3cpDynarchCalendar-hover-week {
  background-color: @slate;
}
.D3cpDynarchCalendar-day-othermonth {
}
.D3cpDynarchCalendar-weekend {
  color: #c22;
}
.D3cpDynarchCalendar-day-today {
  .helvetica-bold;
}
.D3cpDynarchCalendar-day-disabled {
  .opacity(0.5);
}
.D3cpDynarchCalendar-hover-date {
  background-color: @slate;
  color: white;
  .helvetica-bold;
}
.D3cpDynarchCalendar-day-selected {
  background-color: @slate;
  color: white;
  .helvetica-bold;
}



.D3cpDynarchCalendar-bottomBar {
  background-color: @light-slate;
  text-align: center;
  td {
    height: 100%;
    width: 100%;
    padding: 0;
    text-align: center;
  }
}
.D3cpDynarchCalendar-bottomBar-today {
  padding: 8px 20px;
}
.D3cpDynarchCalendar-hover-bottomBar-today {
  color: white;
  background-color: @slate;
}
.D3cpDynarchCalendar-pressed-bottomBar-today {
  color: white;
  background-color: @slate;
}
