.smart-feedback-landing {
  #page {
    .main-content {
      .experience {
        margin: 30px auto;
        width: 90%;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(218,225,230,0.4);
        text-align: center;
        @media (min-width: 641px) {
          margin: 50px auto;
          width: 480px;
        }

        .experience-title {
          margin: 0 10px;
          padding: 40px 10px;
          color: #63687D;
          font-size: 26px;
          border-bottom: 1px solid #B2C3D4;
          box-sizing: border-box;
          line-height: 32px;
          @media (min-width: 641px) {
            margin: 0 32px;
            padding: 60px 10px;
          }
        }

        .experience-choices {
          display: inline-block;
          width: 100%;
          padding: 20px 0 30px;
          @media (min-width: 641px) {
            padding: 50px 0 75px;
          }

          .experience-choice {
            display: inline-block;
            cursor: pointer;
            margin: 4%;
            width: 36%;
            border: 2px solid #8E93F3;
            border-radius: 7.34px;
            box-shadow: 3px 3px 5px 0 #DAE1E6;
            @media (min-width: 641px) {
              margin: 24px;
              width: 144px;
            }
            &:hover {
              background-color: #E3EDFD;
            }
            &.unselected {
              opacity: 0.5;
            }

            .happy-face, .sad-face {
              margin: 25px auto 0 auto;
              width: 62px;
              height: 63px;
              @media (min-width: 641px) {
                margin-top: 45px;
              }
            }
            .happy-face {
              background: url('~@browser/assets/e/images/happy-face.svg');
            }
            .sad-face {
              background: url('~@browser/assets/e/images/sad-face.svg');
            }

            .description {
              margin: 14px auto 25px auto;
              width: 62px;
              color: #8E93F3;
              font-size: 20px;
              font-weight: 500;
              @media (min-width: 641px) {
                margin-bottom: 45px;
              }
            }
          }
        }
      }

      .google-profile-transition {
        display: none;
        margin: 50px auto;
        width: 90%;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(218,225,230,0.4);
        text-align: center;
        @media (min-width: 641px) {
          margin: 124px auto;
          width: 400px;
          padding: 0 40px;
        }

        .happy-face {
          padding: 48px 0;
          img {
            width: 98px;
            height: 98px;
          }
        }
        .transition-title {
          padding: 0 60px;
          color: #63687D;
          font-size: 26px;
          font-weight: bold;
          line-height: 32px;
          @media (min-width: 641px) {
            padding: 0;
          }
        }
        .transition-description {
          padding: 28px 0 60px 0;
          color: #63687D;
          font-size: 18px;
        }
      }
    }
  }
}