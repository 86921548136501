// About section, takes over two left columns.
//
// Consists of:
// - About (description)
// - Features (services, languages, etc)
// - Credits (staff and certifications)
// - Videos
#business-profile-about {
  padding: 20px;
  .helvetica;

  float: left;
  @media @mobile {
    float: none;
  }

  h2, h3 {
    .helvetica-bold;
  }

  &>section {
    // Based on list-basic spacing/border
    padding-bottom: 18px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 18px;
    word-break: break-word;
    &:last-child {
      border-bottom: none;
    }
  }
}

.business-profile-about-description {
  p {
    line-height: 1.4;
  }
}

// Features list consist of two columns.
.business-profile-features {
  margin-right: -1px;
  ul {
    list-style-type: none;
    li {
      padding: 0 0 10px;
    }

    li:before {
      content: "✓ ";
      margin-left: -20px;
    }
  }

  ul + a {
    // The "N more..." link that follows a list left-aligns with the list.
  }

  // Apply to list to hide all but first five items.
  .limit-first-5 li:nth-child(1n+6) {
    display: none;
  }
  // Apply to list to hide all but first three items.
  .limit-first-3 li:nth-child(1n+4) {
    display: none;
  }

  .business-profile-features-column {
    width: 280px;
    margin-right: 20px;
    float: left;
    @media @mobile {
      float: none;
      margin-right: 0;
    }
    margin-bottom: 10px;
  }
  .clearfix;
}


// Credits consist of two columns, one for staff, one for certifications.
.business-profile-staff {
  margin-right: -1px;
  .business-profile-staff-member {
    float: left;
    width: 280px;
    margin-right: 20px;

    h3 {
      padding-top: 0;
    }
    p {
      padding: 0 0 10px;
      &:last-child {
        padding: 0 0 20px;
      }
    }
    @media @mobile {
      float: none;
      margin-right: 0;
    }
  }
  .clearfix;
}

.business-profile-certifications {
  width: 280px;
  overflow: hidden;
  position: relative;

  // We have 280px to work with, and 3 logos, so we limit them to 90px each,
  // and 5px spacing.
  span {
    // Each affiliation logo is 110 x 75, with 5px border that we need to
    // clip, so 100 x 65 of usable image.
    //
    // We have a 90 x 58 box, in which we center an image downscaled to 99 x
    // 64 image.
    display: inline-block;
    width: 90px;
    height: 58px;
    overflow: hidden;
    position: relative;
    margin-right: -1px;
  }
  span a {
    width: 99px;
    height: 67px;
    position: absolute;
  }
  img {
    display: block;
    position: absolute;
    top: -4px;
    left: -4px;
    width: 99px;
    height: 67px;
  }
  @media @mobile {
    float: none;
  }
  .clearfix;
}

