// Styling for the business card.  320px wide.
//
// Import this once in every page that includes the business card.
.business-card {
  padding: 20px;

  // 320px in full view
  // 320-480px in mobile view
  width: 320px;
  @media @mobile {
    width: 100%;
    min-width: 320px;
    max-width: 640px;
  }

  background-color: @lighter-slate;
  @media @mobile {
    border: none;
  }
  .helvetica; // font size and line height

  @media print {
    background: transparent;
  }
}

// Business card header consists of an image (72x72) and everything else is
// floating right of the image.
.business-card-header {
  padding-left: 88px;

  // Limited spacing between header and vertical/reviews.  Business name
  // commonly runs into two lines, even three, too much spacing is doesn't help.
  h2 {
    margin: 0;
    padding: 0;
  }
  // Not an obvious link.
  a {
    color: @body-color;
  }

  // Vertical and rating are both ligher and closer together.
  .business-card-vertical,
  .business-card-rating {
    font-size: 90%;
    padding: 0;
    line-height: 20px;
  }

  padding-bottom: 20px;
  border-bottom: 1px solid @light-slate;
  margin-bottom: 18px;
  .clearfix;
}

.business-card-logo {
  float: left;
  margin-left: -88px;
  .business-logo(70px, 1.0);
}

// Business card address is a single paragraph, using br to break to two lines.
.business-card-address {
}
.business-card-address-locality {
  display: block;
}

// Contact details are one paragraph per item.  Truncate with ellipsis since
// email address and website URL don't break and we don't want them overflowing
// out of the card.
.business-card-contact {
  p {
    .overflow-ellipsis;
  }
}

// Operating hours are one paragraph per set of days, but we use tables, turning
// each paragraph into a row, and spliting days and hours into cells, so we can
// align them properly.  There is no spacing between the rows.
.business-card-hours {
  display: table;
  border: none;
  p {
    display: table-row;
    padding: 0;
  }
  span {
    display: table-cell;
    // Aligns the second column and add some spacing.
    & + span {
      padding-left: 10px;
    }
  }
  // Highlight the current day.
  .current-day {
    color: #000;
  }
}
.business-card-after-hours {
  margin-top: 20px;
  padding: 0;
}


// Business card coupon appears on some cards.  Essentially a link to schedule
// an appointment.
.business-card-coupon {
  margin-top: 20px;
  // Same spacing as regular P.
  text-align: center;
  padding: 10px 20px !important;

  background-color: @light-yellow;
  // Same as Harmony button: hover over card adds drop shadow.
  border: 2px dashed @yellow;
  border-radius:3px;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  //&:hover, &:active { .box-shadow(0 2px 0 rgba(2, 84, 39, 0.3), inset 0 0 0 2px rgba(255, 255, 255, 1.0));}

  // Need dark color for contrast.
  a {
    color: @orange;
    text-decoration: none;
  }
  p {
    padding: 0;
  }
  // Expiration in smaller text, on its own line.
  .business-card-coupon-expires {
    font-size: 55%;
    text-transform: uppercase;
  }
}
.green-primary {
  border: 1px solid #2ca10c;
  border-radius:2px;
  -moz-border-radius:3px;
  background-size: 100%;
  .gradient-vertical(#40bb1f, #2ca10c);
  color: white;
  outline:none;
  &:hover, &.hover {
    .box-shadow(0 2px 0 #ccc, inset 0 0 0 1px rgba(0, 0, 0, 0.1));
  }
  &:active, &.active {
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid #2a353f;
    background-size: 100%;
    .gradient-vertical(#25762f, #1c591d);
  }
  &[disabled=disabled], &.disabled {
    color: rgba(255, 255, 255, 0.3);
    border: 1px solid #171d23;
    background-size: 100%;
    .gradient-vertical(#25426c, #203666);
    &:active, &.active {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

.green-border {
  border: 2px solid #2ca10c;
  border-radius:2px;
  -moz-border-radius:3px;
  background-size: 100%;
  color: #2ca10c;
  outline:none;
  &:hover, &.hover {
    .box-shadow(0 2px 0 #ccc, inset 0 0 0 1px rgba(0, 0, 0, 0.1));
  }
  &:active, &.active {
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid #2a353f;
    background-size: 100%;
    .gradient-vertical(#25762f, #1c591d);
  }
  &[disabled=disabled], &.disabled {
    color: rgba(255, 255, 255, 0.3);
    border: 1px solid #171d23;
    background-size: 100%;
    .gradient-vertical(#25426c, #203666);
    &:active, &.active {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

.business-card-schedule-button {
  margin: 20px 0;

  a {
    .green-primary;
    .helvetica;
    height: 2em;
    line-height: 2em;
    text-align: center;
    display: block;
    width: 280px;
    margin: 0 auto;
    &:hover {
      text-decoration: none;
    }
  }

  @media print {
    display: none;
  }
}

.business-card-access-patient-portal {
  margin: 20px 0;

  a {
    .green-border;
    .helvetica;
    height: 2em;
    line-height: 1.8em;
    text-align: center;
    display: block;
    width: 280px;
    margin: 0 auto;
    &:hover {
      text-decoration: none;
    }
  }

  @media print {
    display: none;
  }
}

// Some business cards end with a small map.
//
// Fancy footwork to center align a map that's 420x240 inside a container that's
// anywhere from 320px to 480px, depending on device width.
.business-card-map {
  position: relative;
  height: 240px;
  margin: 20px -20px -20px -20px;
  overflow: hidden;

  div {
    margin-left: 50%;
  }

  img {
    width: 480px;
    margin-left: -240px;
  }   
}
