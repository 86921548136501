// See https://github.com/h5bp/html5-boilerplate/blob/master/css/main.css

@media print {
  @page {
    margin: 0.5cm;
  }

  * {
    //background: transparent !important;
    color: #000 !important; // Black prints faster
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a, a:visited {
    text-decoration: none;
  }

  a.print[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre, blockquote {
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; /* h5bp.com/t */
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  header, footer {
    display: none;
  }

  body:after {
    display: block;
    content: "Find out more about Demandforce at http://www.demandforce.com";
    padding: 10px 0;
    text-align: center;
  }
}
