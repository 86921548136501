#hop-hotlist-widget {
  main {
    height: 100vh;
    #footer {
      position: fixed;
      bottom: 0;
    }
  }

  #page .side-column {
    height: 95vh;
  }
}