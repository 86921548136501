// Harmony controls
.badge {
  height: 15px;
  min-width: 20px;
  .border-radius(10px);
  background-color: @slate;
  font-size: 1rem;
  line-height: 15px;

  &.dark {
    background-color: @dark-slate;
  }
}

.confirm {
  width: 22px;
  height: 22px;
  background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1189px no-repeat;
  display: inline-block;
}

.alert {
  width: 22px;
  height: 22px;
  background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1151px no-repeat;
  display: inline-block;

  &.transaction {
    width: 29px;
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -923px no-repeat;

    span {
      padding-left: 34px;
      color: @red;
      text-transform: uppercase;
      .helvetica-bold;
      font-size: 1 * @rem;
      line-height: 22px;
      float: left;
    }
  }
}


.select {
  display: inline-block;
  height: 34px;
  position: relative;
  z-index: 3;
  border: 1px solid @gray;
  .box-sizing(border-box);
  .border-radius(2px 0 0 2px);
  .background-clip(padding-box);
  background-size: 100%;
  .gradient-vertical(#ffffff, #f2f2f2);
  .box-shadow(inset -28px 0 0 white);
  cursor: pointer;

  &:active,
  &.active,
  &.open {
    background-size: 100%;
    .gradient-vertical(#ffffff, #f5f5f5);
    .box-shadow(inset -28px 0 0 white);
    &:after {
      border: none;
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
      .gradient-vertical(rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.15));
    }
  }
  &.open ul {
    display: block;
  }
  select,
  span {
    .helvetica-regular;
    font-size: 1.2 * @rem;
    position: relative;
    line-height: 34px;
    z-index: 2;
    height: 34px;
    margin-left: 0;
    margin-right: 0;
    padding: 0 40px 0 10px;
    cursor: pointer;
  }

  select {
    .appearance(none);
    border: none;
    background: none;
    outline: none;

    &.hidden {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }

  ul {
    display: none;
    position: absolute;
    top: 37px;
    left: 0;
    z-index: 5;
    border: 1px solid @gray;
    margin: 0;
    width: 100%;
    .background-clip(padding-box);
    .border-radius(2px);
    background-color: white;
    .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2));
    list-style: none;
    cursor: pointer;

    li {
      font-size: 1.2 * @rem;
      line-height: 34px;
      height: 34px;
      padding: 0 10px;
      margin: 1px;

      &:hover {
        background-color: @blue;
        color: white;
      }
    }
  }

  &:after {
    display: block;
    position: absolute;
    z-index: 1;
    top: -1px;
    right: -3px;
    width: 30px;
    height: 34px;
    content: "";
    .box-sizing(border-box);
    border-width: 1px;
    .box-shadow(inset 1px 0 0 white);
    border-style: solid;
    border-color: #d5d5d5;
    .border-radius(0 2px 2px 0);
    .background-clip(padding-box);
    .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -155px no-repeat, #ffffff, #f2f2f2);
  }

  &:hover,
  &.hover {
    background-size: 100%;
    .gradient-vertical(#ffffff, #f5f5f5);
    .box-shadow(inset -28px 0 0 white);
    &:after {
      border-color: @blue;
      .box-shadow(none);
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -243px no-repeat, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.15));
    }
  }
}

.radio-item {
  display: table;
  margin-bottom: 2px;
}
.checkbox-item {
  display: table;
  margin-bottom: 2px;
}
.labeled-textbox,
.labeled-textarea {
  display: inline-block;
  margin: 12px 4px 0 0;
  label {
    .helvetica-bold;
    display: block;
    margin-bottom: 4px;
    padding-left: 2px;
    &.error {
      color: #d34a2e;
    }
  }
}

// Validation
.validation-success,
.validation-error {
  padding: 15px;
  h1 {
    font-size: 1.4 * @rem;
    .helvetica-bold;
    box-shadow: none;
    padding: 2px 0 1px 30px;
    line-height: 22px;
    border: none;
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1189px no-repeat;
  }
  p {
    font-size: 1.2 * @rem;
    padding: 10px 0 0 0;
  }
}
.validation-success {
  background-color: @light-green;
  border: 1px solid #90c128;
  h1 {
    color: @dark-green;
  }
}
.validation-error {
  background-color: @light-pink;
  border: 1px solid #d34a2e;
  h1 {
    color: #d03202;
  }
}

// Passive alerts
.passive-alert {
  position: relative;
  padding: 12px 8px;
  background-color: #f3f5f7;

  h1 {
    display: inline-block;
    color: @darker-gray;
    font-size: 1.4 * @rem;
    .helvetica-bold;
    box-shadow: none;
    padding: 0 0 0 30px;
    height: 22px;
    min-width: auto;
    line-height: 22px;
    border: none;
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1151px no-repeat;
  }
  p {
    display: inline-block;
    font-size: 1.2 * @rem;
    margin-left: 20px;
    padding: 0;
    width: auto;
    height: 22px;
    line-height: 22px;
    &.dismiss {
      display: inline-block;
      text-align: right;
    }
  }
} // .passive-alert


// Displaying multiple errors
.multiple-errors {
  position: relative;
  background-color: @lighter-gray;
  height: 40px;

  .badge-area {
    height: 40px;
    width: 40px;
    border-right: 1px solid white;
    display: inline-block;
    text-align: center;

    &.open {
      background-color: @darker-slate;
    }
    .badge {
      display: inline-block;
      color: white;
      text-align: center;
      margin-top: 13px;
    }
  }

  .message-area {
    height: 40px;
    border-left: 1px solid @lighter-slate;
    display: inline-block;
    padding-left: 8px;
    margin-left: -4px;
    vertical-align: middle;

    h1 {
      display: inline-block;
      min-width: 200px;
      line-height: 0;
      color: @darker-gray;
      font-size: 1.4 * @rem;
      .helvetica-bold;
      box-shadow: none;
      padding: 0;
      border: none;
      margin-top: 20px;
    }
    p {
      display: inline-block;
      font-size: 1.2 * @rem;
      padding: 0;
      width: auto;
      line-height: 0;

      &.dismiss {
        position: absolute;
        right: 20px;
        margin-top: -3px;
      }
    }
  }
} // .multiple-errors


// Inline confrimation
.inline-confirmation {
  position: relative;
  padding: 15px;
  background-color: @light-green;
  border: 1px solid #90c128;
  display: block;

  &.overlay {
    display: inline-block;
    .border-radius(3px);
    .background-clip(padding-box);
    .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2));
  }

  p {
    font-size: 1.2 * @rem;
    padding: 4px 0 3px 30px;
    display: inline;
    line-height: 22px;
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1189px no-repeat;
  }
  button {
    position: absolute;
    border: none;
    height: 14px;
    width: 14px;
    right: 12px;
    top: 50%;
    margin-top: -7px;
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1743px;
  }
  div b {
    position: absolute;
    top: 18px;
    left: -7px;
    margin: 0;
    border-top: 0;
    border-top: 6px solid transparent;
    border-right: 7px solid @light-green;
    border-bottom: 6px solid transparent;
    padding: 0;
    width: 0;
    height: 0;

    &.border-notch {
      top: 18px;
      border-right-color: #90c128;
      left: -8px;
    }
    &.shadow-notch {
      top: 18px;
      border-right-color: #90c128;
      left: -7px;
      margin-top: 4px;
    }
  }
} // .inline-confirmation


// Tooltips
.tooltip {
  position: absolute;
  background-color: #1f3246;
  min-height: 48px;
  display: table;
  .opacity(0);
  .border-radius(2px);

  button {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    height: 14px;
    width: 14px;
    margin-left: 20px;
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1743px;
  }
  p {
    font-size: 1.2 * @rem;
    vertical-align: middle;
    padding: 0;
    color: white;
    margin: 10px;
    max-width: 500px;
  }

  &.closeable p {
    margin-right: 28px;
  }

  .tooltip-notch {
    position: absolute;
    border: 20px solid black;
    height: 0;
    width: 0;

    &.top-left {
      top: -12px;
      left: 8px;
      border-bottom: 12px solid #1f3246;
      border-top: 0 solid transparent;
      border-left: 0 solid transparent;
      border-right: 12px solid transparent;
    }
    &.top-right {
      top: -12px;
      right: 8px;
      border-bottom: 12px solid #1f3246;
      border-top: 0 solid transparent;
      border-left: 12px solid transparent;
      border-right: 0 solid transparent;
    }
    &.bottom-right {
      bottom: -12px;
      right: 8px;
      border-bottom: 0 solid transparent;
      border-top: 12px solid #1f3246;
      border-left: 12px solid transparent;
      border-right: 0 solid transparent;
    }
    &.bottom-left {
      bottom: -12px;
      left: 8px;
      border-bottom: 0 solid transparent;
      border-top: 12px solid #1f3246;
      border-left: 0 solid transparent;
      border-right: 12px solid transparent;
    }
  }
} // .tooltip


// HTML input controls
input[type=checkbox],
div.checkbox {
  height: 18px;
  width: 18px;
  display: table-cell;
  .appearance(none);
  background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1605px;

  & + label {
    padding-left: 4px;
    line-height: 18px;
    display: table-cell;
  }
  input {
    opacity: 0;
  }

  &:hover {
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1273px;
    &:checked, &.checked {
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1439px;
    }
  }
  &:checked, &.checked {
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1439px;
  }
}
div.checkbox input[type=checkbox] {
  height: 10px;
}


input[type=radio],
div.radio {
  height: 18px;
  width: 19px;
  display: inline-block;
  vertical-align: middle;
  .appearance(none);
  background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1133px;

  & + label {
    padding-left: 4px;
    line-height: 18px;
    display: inline-block;
    vertical-align: middle;
  }
  input {
    .opacity(0);
  }

  &:hover {
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -985px;
    &:checked, &.checked {
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -665px;
    }
  }
  &:checked, &.checked {
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -665px;
  }
}
div.radio input[type=radio] {
  height: 10px;
}

input[type=text],
input[type=email],
input[type=search] {
  outline: none;
  height: 28px;
  border: 1px solid @gray;
  padding: 0 6px;
  .background-clip(padding-box);
  .border-radius(2px);

  &.error {
    border: 1px solid #d34a2e;
    .background-clip(padding-box);
    .border-radius(2px);
    background-color: white;
    &:focus {
      .box-shadow(0 0 0 2px rgba(211, 74, 46, 0.25));
    }
  }
  &.prompt {
    border: 1px solid @blue;
    .background-clip(padding-box);
    .border-radius(2px);
    background-color: white;
    .box-shadow(0 0 0 2px rgba(57, 101, 206, 0.25));
  }
  &.subtotal {
    border: 1px solid #fbdfa3;
    background-color: @light-yellow;
    color: #323232;
    font-size: 1.4 * @rem;
    .helvetica-bold;
    height: 22px;
  }
  &.plus {
    background: white url('~@bimages/icons-s2b11c90dfb.png') 102px -1630px no-repeat;
  }

  &:focus {
    border: 1px solid #a0a0a0;
    .background-clip(padding-box);
    .border-radius(2px);
    background-color: white;
    .box-shadow(0 0 0 2px rgba(161, 161, 161, 0.25));
  }

  &[placeholder].placeholder {
    font-style: italic;
  }
  &[placeholder]::-moz-placeholder {
    font-style: italic;
  }
  &[placeholder]::-webkit-input-placeholder {
    font-style: italic;
  }

  &[disabled='disabled'] {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
    border: 1px solid @gray;
    background-color: white;
    .background-clip(padding-box);
    .border-radius(2px);
  }
}
input[type=search] {
  width: 200px;
  .appearance(none);
  background: white url('~@bimages/icons-s2b11c90dfb.png') 167px -1298px no-repeat;
  &::-webkit-search-cancel-button {
    .appearance(none);
  }
}

textarea {
  outline: none;
  border: 1px solid @gray;
  padding: 6px;
  font-style: normal;
  color: #7f7f7f;
  width: 220px;
  height: 64px;
  overflow: auto;
  .background-clip(padding-box);
  .border-radius(2px);
  .helvetica-regular;
}


// Show more
.show-more div {
  width: 0;
  height: 0;
  border-left: 5px solid #3f3f3f;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  margin-top: 2px;
  display: inline-block;

  &.open div {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #3f3f3f;
    margin-top: 5px;
    display: inline-block;
  }
} // .show-more

.show-more-label {
  div {
    width: 0;
    height: 0;
    border-left: 5px solid @blue;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    margin-top: 2px;
    display: inline-block;
  }

  &.open div {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid @blue;
    margin-top: 5px;
    display: inline-block;
  }

  a {
    font-size: 1.2 * @rem;
    color: @blue;
  }

  &.disabled {
    div {
      .opacity(0.4);
    }
    a {
      .opacity(0.4);
    }
  }

  &:hover {
    div {
      border-left: 5px solid #2c4d9b;
    }
    &.open div {
      border-left: 4px solid transparent;
      border-top: 5px solid #2c4d9b;
    }
    a {
      color: #2c4d9b;
      text-decoration: underline;
    }
  }
} // .show-more-label


// Carousel
.carousel-container {
  padding: 20px;
  height: 136px;
  .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2));
  border: 1px solid @light-slate;
}
.carousel {
  position: relative;
  height: 100%;

  button {
    position: absolute;
    border: none;
    top: 50%;
    margin-top: -12px;

    &.left {
      left: 0;
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1385px no-repeat;
      &:hover {
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1331px no-repeat;
      }
    }
    &.right {
      right: 0;
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1349px no-repeat;
      &:hover {
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1367px no-repeat;
      }
    }
  }

  .paging {
    position: absolute;
    bottom: 0;
    text-align: center;
    margin-bottom: 0;
    max-width: inherit;

    li {
      width: 5px;
      height: 5px;
      margin: 0 6px;
      display: inline-block;
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -2271px no-repeat;
      &.current {
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -2276px no-repeat;
      }
    }
  }
} // .carousel


// Lists
.list-basic {
  list-style: none;
  margin: 0 0 20px 0;
  li {
    padding: 18px 0;
    border-bottom: 1px solid @light-gray;
    &:last-child {
      border-bottom: none;
    }

    input {
      float: left;
      margin: 0 8px 0 0;
    }
  }
} // .list-basic

.list-complex {
  list-style: none;
  margin: 0 0 20px 0;
  width: 265px;

  li {
    padding: 8px 0 8px 30px;
    border-bottom: 1px solid @light-gray;

    &.row-alert {
      .box-shadow(inset 5px 0 @red);
      span {
        color: @red;
      }
    }

    &.row-open {
      .box-shadow(inset 5px 0 @dark-yellow);
      span {
        color: @dark-yellow;
      }
    }

    span {
      font-size: 1.2 * @rem;
      display: block;
      .helvetica-bold;
    }
    &:last-child {
      border-bottom: none;
    }
  }
} // .list-complex


// Tables
table {
  width: 100%;

  &.compact {
    tr td {
      height: 30px;
    }
  }

  tr {
    background-color: transparent;
    &:hover {
      background-color: @lightest-slate;
    }

    &:first-child:hover {
      background-color: transparent;
    }
    &:first-child.checked {
      border-top: none;
      td {
        background-color: transparent;
      }
      & + tr {
        border-top: none;
      }
    }

    &.alert-row {
      td:first-child {
        .box-shadow(inset 5px 0 @red);
      }
      td:last-child {
        color: @red;
      }
    }

    &.confirm-row td:first-child {
      .box-shadow(inset 5px 0 @dark-green);
    }

    &.section-header {
      background-color: #f3f3f7;
      border-top: 1px solid #ebecef;
      td {
        .helvetica-bold;
        font-size: 1.4 * @rem;
        .box-shadow(inset 0 4px 0 rgba(0, 0, 0, 0.03));
      }
      div {
        width: 0;
        height: 0;
        border-left: 5px solid #3f3f3f;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        float: right;
        margin-top: 2px;
      }
      &.open div {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #3f3f3f;
        float: right;
        margin-top: 5px;
      }
    }

    th {
      background-color: #e3e9f3;
      height: 32px;
      padding: 0 10px;
      text-transform: uppercase;
      color: #6d8ba7;
      border-left: 1px solid @light-slate;
      .box-shadow(0 2px 0 #cedbea);
      .background-clip(padding-box);

      &:first-child {
        .border-radius(2px 0 0 0);
        border-left: none;
      }
      &:last-child {
        .border-radius(0 2px 0 0);
      }

      &.sort-desc {
        background-color: @light-slate;
        span {
          display: inline-block;
          height: 0;
          width: 0;
          border-bottom: 0 solid transparent;
          border-top: 7px solid @slate;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          margin: 0 0 2px 4px;
        }
      }
    }

    td {
      height: 50px;
      padding: 0 10px;
      border-bottom: 1px solid @gray;
      border-left: 1px dotted @gray;
      &:first-child {
        border-left: none;
      }

      &.error {
        border: 1px solid @red;
        color: @red;
        border-style: double;
      }

      &.balance {
        .helvetica-bold();
      }
    }
  }
} // table


// Media and Avatar
.media, .avatar {
  .border-radius(3px);
  display: inline-block;
  position: relative;
  &:before {
    .background-clip(padding-box);
    .border-radius(2px);
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &:hover {
    border-color: @gray;
  }
  img {
    .background-clip(padding-box);
    .border-radius(2px);
    display: block;
  }
}
.media {
  border: 1px solid rgba(0, 0, 0, 0);
  &:before {
    .box-shadow(inset 0 0 4px 0 rgba(0, 0, 0, 0.2));
  }
}
.avatar {
  border: 1px solid @light-gray;
  &:before {
    .box-shadow(inset 0 0 0 4px white);
  }
}


// Activity feed
.activity-feed {
  padding-left: 30px;
  border-left: 1px dotted @gray;
  font-size: 1.2 * @rem;

  h1 {
    font-size: 2 * @rem;
    .helvetica-light;
    font-stretch: normal;
    padding: 0;
    box-shadow: none;
    border: none;
  }

  &.narrow {
    width: 200px;
    div p.timestamp {
      top: auto;
      right: auto;
      position: relative;
    }
  }

  .new-activity {
    margin-top: 20px;
    textarea {
      width: 100%;
    }
    .control-area {
      height: 36px;
      margin-right: -14px;
      margin-top: -6px;
      border: 1px solid @gray;
      padding: 8px 8px 0 8px;
      a {
        padding-left: 18px;
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1173px no-repeat;
      }
      input {
        float: right;
      }
    }
  }

  a {
    .helvetica-bold;
  }

  div {
    position: relative;
    margin-bottom: 20px;

    p {
      margin: 0;
      width: auto;
      padding: 0;
      &.timestamp {
        font-style: italic;
        color: @dark-gray;
        position: absolute;
        top: 0;
        right: -14px;
      }
    }

    &.date {
      p.date-title {
        .helvetica-light;
        font-size: 2 * @rem;
      }
      p.timestamp {
        text-transform: uppercase;
        .helvetica-bold;
        font-size: 1.2 * @rem;
        color: @darker-gray;
        font-style: normal;
        position: relative;
        right: auto;
      }
    }
  }

  .icon {
    margin-left: -42px;
    margin-top: -5px;
    float: left;
    background-color: white;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    &.dot {
      width: 22px;
      height: 22px;
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -2175px no-repeat;
      border-top: transparent;
      border-bottom: transparent;
      margin-left: -41px;
      margin-top: -1px;
    }
    &.date {
      width: 22px;
      height: 22px;
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -2153px no-repeat;
      border-top: transparent;
      border-bottom: transparent;
      margin-left: -41px;
      margin-top: -1px;
    }
  }

} // .activity-feed
