@import "../../lib/slick";
.new-business-profile{
  // business header
  .header{
    background: #F6F8FB;
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    overflow: hidden;
    .rating {
      width: 27%;
      float: left;
      .rating-value{
        font-family: DINNextLTPro-Medium, helvetica, arial, sans-serif;
        font-size: 24px;
        color: #404040;
        line-height: 29px;
        padding-left: 30px;
      }
    }
    .basic {
      float: left;
      width: 73%;
      .business-name {
        word-wrap: break-word;
      }
      .available-msg{
        color: #56B349;
        padding-right: 5px;
      }
      .not-available-msg{
        color: #F2B835;
        padding-right: 5px;
      }
      padding-left: 10px;
    }
  }

  // navigation bar
  .nav{
    .nav-item{
      padding: 5px;
      height: 50px;
      background: #E3EAF3;
      border: 1px solid #CAD7E8;
      font-family: HelveticaNeue-Light, helvetica, arial, sans-serif;
      font-size: 14px;
      color: #3A506C;
      line-height: 17px;
      display: table;

      a{
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        &:hover{
          text-decoration: none;
        }
        &:visited{
          color: #3A506C;
        }
        &:link{
          color: #3A506C;
        }
        &.active{
          font-family: HelveticaNeue-Medium, helvetica, arial, sans-serif;
          font-size: 14px;
          color: #3A506C;
          line-height: 17px;
        }
      }
    }
    .arrow-left, .arrow-right{
      position: absolute;
      top: 15px;
      cursor: pointer;
    }
    .arrow-right{
      right: 0;
    }
    .arrow-left{
      left: 0;
    }
  }
  
  // offers
  .offers{
    overflow: hidden;
    h2{
      padding-bottom: 10px;
      padding-left: 10px;
    }
    .offer-title{
      padding-bottom: 10px;
    }
    .offer-expiry{
      color: lightgrey;
      padding-bottom: 10px;
    }
    .offer-items{
      float: left;
      width: 80%;
      padding: 10px;
    }
    .offer{
      padding: 10px;
      border: 2px dashed black;
      margin: 10px;
    }
    .arrow-left{
      float: left;
      width: 10%;
      padding-left: 5px;
      padding-top: 50px;
      text-align: center;
      cursor: pointer;
    }
    .offer-container{
      border: 1px solid lightgrey;
      -moz-box-shadow: 0px 1px 0 0 #ccc;
      -webkit-box-shadow: 0px 1px 0 0 #ccc;
      box-shadow: 0px 1px 0 0 #ccc;
    }
    .arrow-right{
      float: left;
      width: 10%;
      padding-right: 5px;
      padding-top: 50px;
      text-align: center;
      cursor: pointer;
    }
  }
  .slick-disabled{
    visibility: hidden;
  }
  // business-details
  .details {
    padding-left: 10px;
    padding-bottom: 20px;
    .business-details-box {
      padding-bottom: 10px;
    }
    .business-details {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-family: HelveticaNeue, helvetica, arial, sans-serif;;
      color: #404040;
      line-height: 20px;
    }
    .details-hours {
      padding: 10px 0 ;
      .detail-hours-block {
        padding-bottom: 0; 
      }
    }
    .details-address {
      padding: 10px 0;
    }
    .expand-details {
      padding-top: 10px;
    }
    .arrow-right {
      float: left;
      width: 15px;
      height: 0;
      border-style: solid;
      border-width: 5px 0px 5px 10px;
      border-color: transparent transparent transparent #007bff;
    }
  }
  .details-link {
    padding-left: 5px;
  }

  // common styles
  body{
    font-size: 14px;
    color: #404040;
    line-height: 20px;
  }
  h2{
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    margin: 0;
    padding: 0;
    font-family: HelveticaNeue-Light, helvetica, arial, sans-serif;
    font-size: 20px;
    color: #404040;
    line-height: 24px;
  }
  h1{
    font-family: HelveticaNeue-Bold, helvetica, arial, sans-serif;
    box-shadow:none;
    webkit-box-shadow:none;
    border-bottom:inherit;
    font-size: 16px;
    color: #404040;
    line-height: 19px;
    margin: 0;
    padding: 0;
  }

  h3{
    padding: 0;
  }
  a{
    font-family: HelveticaNeue, helvetica, arial, sans-serif;
    font-size: 14px;
    color: #365EBF;
    line-height: 16px;
    cursor: pointer;
  }
  margin-top: 25px
}
