
.closeButton {
  cursor: pointer;
  color: #999;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 6px;
}
.business-card-coupon .description,
.coupon-modal .content .description {
  font-size: 16px;
}

/* Modal */
body.modal-open {
	overflow: hidden;
}

.coupon-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;
  padding: 25px;
}

.coupon-modal .background {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  pointer-events: none;
}

.coupon-modal .content {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  position: relative;
  margin: 150px auto;
  padding: 20px;
  overflow: auto;
  text-align: center;
  line-height: 1.5;
  border-radius: 4px;
  z-index: 11;
}

.coupon-modal img {
	height: 106px;
  margin: 20px auto 10px;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.coupon-modal .buttons {
	padding-top: 20px;
  border-top: 1px solid #ccc;
  margin-top: 20px;
}

.coupon-modal .btn {
	display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
	background-color: #0098CD;
  border: 1px solid #0098CD;
  color: #fff;
  border-radius: 2px;
  
  transition: 100ms all;
  text-decoration: none;
}

.coupon-modal .btn:hover {
	background-color: #fff;
  color: #0098CD;
}

.coupon-modal .btn.inverse {
	background-color: #fff;
  color: #0098CD;
}

.coupon-modal .btn.inverse:hover {
	background-color: #0098CD;
  color: #fff;
}



/* mobile */
@media (max-width: 599px) {
  .coupon-modal .content {
  	margin-top: 50px;
  }
}

