select {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    select {padding-right:18px}
  }
  &:focus {
    border: 1px solid #3965ce;
  }
  padding:13px;
  width:290px;
  margin: 0;
  -webkit-border-radius:3px;
  -moz-border-radius:3px;
  border-radius:3px;
  background: #fff;
  color:#404040;
  border:1px solid #ccc;
  outline:none;
  display: inline-block;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  font-size:0.9em;
  cursor:pointer;
  margin-bottom:20px;
}
label.dropdown-select { position:relative; }
label.dropdown-select:after {
  content:'';
  width:12px;
  height:12px;
  background:url('~@bimages/select-carrot-down.png');
  background-size:100%;
  background-repeat:no-repeat;
  right:15px;
  top:3px;
  padding:0 0 2px;
  position:absolute;
  pointer-events:none;
}
.availability-calendar-main {
  border-radius: 3px;
  background: white;

  padding:0 20px 20px 10px;
  .availability-error {
    color:red;
  }
  .availability-table {
    display:inline-block;
    padding:0 10px;
    overflow:hidden;
    border:1px solid @gray;
    border-radius:3px;
    color:@body-color;
    width:100%;
    min-width:597px;
    max-width:825px;
  }
  .availability-error, .datetime-warning {
    border: 1px solid;
    color: #e77700;
    padding: 15px;
    text-align: center;
    width:100%;
    max-width:825px;
    i {
      margin-right: 0.25em;
    }
  }
  section{
    display:none;
    &.selected-week {
      display: block;
    }
  }
  label:before {
    content:'';
    right:6px; top:0px;
    width:20px; height:20px;
    background:#f8f8f8;
    position:absolute;
    pointer-events:none;
    display:block;
  }
  .clearfix { overflow:hidden; }
  .column {
    min-width:0;
    width:14.28%;
    width:calc(100% / 7);
    float:left;
    text-align:center;
    &.today { background-color:@off-white; }
    &:last-child {
      .date, .time {
        border-right:none;
      }
    }
  }
  .date {
    padding:.9em .6em .4em .6em;
    border-bottom:1px solid @gray;
    border-right:1px solid @lightest-gray;
    &.past-date { color:@dark-gray; }
    .datenum {
      font-weight:400;
      font-size:1.7em;
    }
    .day {
      display:block;
      font-weight:300;
      font-size:.9em;
      color:@dark-gray;
      margin-top:-2px;
    }

  }
  .time {
    border-right:1px solid @lightest-gray;
    padding:.2em .3em;
    border-bottom:1px solid @lightest-gray;
    height: 33px;
    &:last-child { border-bottom:none; }
    a {
      text-decoration:none;
      font-weight:700;
      font-size:14px;
      padding:.3em;
      border-radius:3px;
      display:block;
      &.active {
        color:@blue;
        &:visited { color:@blue; }
        &:hover { background-color:@lightest-slate; }
        &:active, &.selected {
          background-color:@blue;
          color:@background-color;
        }
      }
      &.disabled {
        color:@body-color;
        &:hover, &:focus {
          background:@background-color;
          cursor:default;
          color:@body-color;
        }
      }
      &.empty { height:26px; }
    }
  }
  .availability-week-nav {
    font-size:1.2em;
    ul {
      list-style:none;
      margin:0;
      font-weight:500;
      width:90%;
      max-width: 810px;
      text-align:center;
      @media screen and (min-width:641px) { min-width:597px; }
      .arrow-right, .arrow-left { display:none; }
      li {
        float:left;
        width:25%;
        padding:1em 0;
        text-align:center;
        .week-light {
          font-weight:300;
          color:#fff;
        }
        &.selected-week {
          span.week-light {
            color:@blue;
            @media @mobile { color:#fff; }
          }
        }
        &.selected-week a {
          color: @dark-slate;
        }
        &.selected-week a:hover {
         text-decoration:none;
          cursor:default;
          .week-light {
            text-decoration:none;
          }
        }
      }
    }
  }
  .no-availability {
    color:#888;
    font-style:italic;
    padding:20px 10px;
    font-weight:300;

    .next-availability{
      font-weight:500;
      font-style: normal;
      font-size:1.0em;
      color:@body-color;
    }
  }
/********
 * Begin Mobile Styles
 ********/

  @media @mobile {
    .column:nth-child(2) {
      .times.selected { margin-left:-100%; }
    }
    .column:nth-child(3) {
      .times.selected { margin-left:-200%; }
    }
    .column:nth-child(4) {
      .times.selected { margin-left:-300%; }
    }
    .column:nth-child(5) {
      .times.selected { margin-left:-400%; }
    }
    .column:nth-child(6) {
      .times.selected { margin-left:-500%; }
    }
    .column:nth-child(7) {
      .times.selected { margin-left:-600%; }
    }
    .time {
      padding:0;
      border-right:none;
      border-bottom:none;
      height: auto;
      a {
        padding:1.0em .6em;
        border-radius:0;
        &.empty { display:none; }
      }
    }
    .date {
      padding:.7em 0;
      cursor:pointer;
      &.disabled, &.past-date {
        color:@dark-gray;
        background-image: url(~@bimages/stripes-disabled.png);
        opacity:0.5;
      }
      &.selected {
        background-color:@blue;
        color:@background-color;
      }
      &.selected, &.disabled {
        cursor:default;
      }
      .day { display:none; }
    }
    .times {
      display:none;
      &.selected {
        display:block;
        width:700%;
        text-align:left;
        border-right:none;
      }
    }
    .availability-table {
      padding:0;
      min-width:0;
    }
    .availability-week-nav {
      ul { max-width:320px; margin:0 auto; padding:0; }
      ul li {
        float:none;
        width:100%;
        display:none;
        &.selected-week {
          display:block;
        }

      }
      span {
        &.arrow-right, &.arrow-left {
            display:inline-block !important;
            cursor:pointer;
        }
      }
      .arrow-right, .arrow-left {
        display:block-inline !important;
        padding:5px 15px 10px 15px;
        border-radius:3px;
        border:1px solid @gray;
        font-size:40px;
        font-weight:400;
        margin-top:-8px;
        background: @background-color; /* Old browsers */
        background:-moz-linear-gradient(top,  @background-color 0%, @off-white 100%);
        background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,@background-color), color-stop(100%,@off-white)); /* Chrome,Safari4+ */
        background:-ms-linear-gradient(top,  @background-color 0%,@off-white 100%); /* IE10+ */
        color:@blue;
        &.disabled { color:@gray; }
        &:hover { cursor:pointer; }
      }
      .arrow-left {
        float:left;
      }
      .arrow-right {
        float:right;
      }
    }
  }
}

/********
 * End Mobile Styles
 ********/

.calendar-message {
  font-style: italic;
  font-size: 0.9em;
  color: @dark-gray;
  padding:0 10px;
  @media @mobile {
    p { margin-top:5px; padding:0; }
    .calendar-message-link { display:block; }
  }
}
