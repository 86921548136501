// Various helper functions and mixins
// Creates a top to bottom gradient between two colors.
.gradient-vertical(@startColor, @endColor) {
  background-color: @endColor;
  background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
  background-image: linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
  background-repeat: repeat-x;
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down
}

// Creates a top to bottom gradient between two colors with a background image.
//
// Note that IE8 will not show the background image.
.gradient-image(@image, @startColor, @endColor) {
  background-color: @endColor;
  background: @image, -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
  background: @image, -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
  background: @image, -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
  background: @image, linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down
}
