#textoptin-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  .main-content {
    @media (min-width: 641px) {
      margin: 15px auto 30px;
      max-width: 540px;
    }
  }
  .masthead {
    div {
      h1 {
        font-size: 20px;
      }
      @media (min-width: 641px) {
        padding: 55px 0 0;
      }
    }
  }
  .header-image {
    margin-top: 14px;
    width: 180px;
    @media (min-width: 641px) {
      max-width: 350px;
      width: 100%;
    }
  }
  .optin-form {
    margin-top: 22px;
    padding: 0 22px;
    @media (min-width: 641px) {
      text-align: center;
    }
    .action-button {
      font-size: 16px;
      margin-top: 10px;
      @media (min-width: 641px) {
        margin-top: 20px;
        max-width: 350px;
        margin-top: 5px;
        height: 34px;
        font-size: 14px;
      }
    }
  }
  .prompt-box {
    @media (min-width: 641px) {
      padding: 28px 32px;
    }
    &-container {
      margin: 16px 0 24px;
      padding: 0 22px;
      @media (min-width: 641px) {
        margin-top: 28px;
        padding: 0;
      }
    }
  }
  .input-error-message {
    @media (min-width: 641px) {
      margin: 7px auto;
      max-width: 350px;
      text-align: left;
    }
  }
}
.optin-success-box{
  margin: 40px 0 0 0;
  div.description{
    font-size: 16px;
    line-height: 1.3em;
  }
}
