// Reviews section, takes over two left columns.
//
// The top part is either satisfaction score, or H2.
#business-profile-reviews {
  padding: 0 20px;
  clear: both;

  // Review H2 set to align with network module.
  h2 {
    height: 2.6em;
    padding-bottom: 0;
    @media @mobile {
      height: auto;
      padding-bottom: inherit;
    }
  }
}

// Survey satisfaction value, alternative to the H2.
.business-profile-satisfaction {
  padding-bottom: 20px;

  .business-profile-satisfaction-score {
    .pfdintextpro-bold;
    font-size: 3.4 * @rem;
    line-height: 1.3;
    display: block;
    color: @dark-green;
    & + span {
      font-size: 1.3 * @rem;
      text-transform: uppercase;
      display: block;
    }
  }
}

// Wraps around the reviews and load more button, styles them as activity feed,
// so each child element should be a div.
.business-profile-reviews {
  .helvetica;
  padding-bottom: 20px;

  @media print {
    border: none;
    padding-left: 0;
  }
}

// Review consists of header with stars, author name and customer
// since/featured.  Next are the blockquote review body, and finally the
// relative timestamp.
.business-profile-review {
  position: relative;
  padding-bottom:20px;
  border-bottom:1px solid @lighter-gray;
  // Review icon is the activity feed dot, except for featured review that uses
  // the confirm (checkmark).
  .business-profile-review-icon {
    .activity-feed > .icon;
    .activity-feed > .icon.dot;
    .business-profile-review-featured & {
      .confirm;
    }
    @media print {
      display: none;
    }
  }

  [itemprop="author"] {
    .helvetica-bold;
    font-style: normal;
  };

  // Customer since, verified customer, featured review, all show up in green.
  .business-profile-review-certified {
    font-size:.8em;
    color: @dark-green;
    &:before {
      content: " — ";
    }
  }

  p {
    line-height: 1.4 !important;
    margin: 0.6em 0 !important;
    padding: 0;
  }

  .timestamp a {
    color: @darker-gray-300;
    .helvetica-regular;
  }

  // Response from the business has light gray background.
  .business-author {
    color:@slate;
    font-weight:300;
  }
  .business-profile-review-response {
    border:1px solid @lighter-slate;
    background-color: @lightest-slate;
    padding:20px;

    p {
      line-height: 1.4;
      margin: 0.5em 0;
      padding: 0;
    }
  }
}


// Last div in the reviews activity feed contains the button for loading more
// reviews.
#business-profile-reviews-load-more {
  button {
    .button.secondary;
    .button;
    display: block;
    text-align: center;
    margin: 0 20px 0 0;
    width: 100%;
    max-width: 480px;
  }

  @media print {
    display: none;
  }
}
