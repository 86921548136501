// Buttons of all kinds
.button {
  .background-clip(padding-box);
  .border-radius(2px);
  .helvetica-regular;
  cursor: pointer;
  font-size: 1.4 * @rem;
  line-height: 34px;
  height: 34px;
  padding: 0 20px;
  border: 0;
  margin-bottom: 2px;
  .box-sizing(border-box);
  .box-shadow(inset 0 0 0 1px rgba(151, 151, 151, 0.4));
  background-size: 100%;
  .gradient-vertical(#ffffff, #f2f2f2);
  color: #404040;

  &.mini {
    font-size: 1.2 * @rem;
    line-height: 24px;
    height: 24px;
  }

  &.plus {
    padding: 0 15px 0 25px;
    .box-shadow(inset 0 0 0 1px rgba(151, 151, 151, 0.4));
    .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -1811px no-repeat, #ffffff, #f2f2f2);
    &:hover, &.hover {
      .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(151, 151, 151, 0.4));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -1811px no-repeat, #ffffff, #f5f5f5);
    }
    &:active, &.active {
      background-color: white;
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1879px no-repeat;
    }
    &[disabled=disabled], &.disabled {
      background-color: white;
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1913px no-repeat;
    }
  }

  &.primary {
    .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
    .gradient-vertical(#4376cf, #3558c1);
    color: white;

    &:hover, &.hover {
      .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#4376cf, #3558c1);
    }
    &:active, &.active {
      color: rgba(255, 255, 255, 0.5);
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
      background-size: 100%;
      .gradient-vertical(#274389, #3459a5);
    }
    &[disabled=disabled], &.disabled {
      color: white;
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#bccaeb, #a5b9e4);
      &:active, &.active {
        color: white;
      }
    }
  }

  &.secondary {
    color: #3965ce;
    &:active, &.active {
      color: rgba(57, 101, 206, 0.5);
    }
    &[disabled=disabled], &.disabled {
      color: rgba(57, 101, 206, 0.3);
      &:active, &.active {
        color: rgba(57, 101, 206, 0.3);
      }
    }

    &.plus {
      .box-shadow(inset 0 0 0 1px rgba(151, 151, 151, 0.4));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -1845px no-repeat, #ffffff, #f2f2f2);
      &:hover, &.hover {
        .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(151, 151, 151, 0.4));
        .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -1845px no-repeat, #ffffff, #f5f5f5);
      }
      &:active, &.active {
        background-color: white;
        .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1947px no-repeat;
      }
      &[disabled=disabled], &.disabled {
        background-color: white;
        .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1981px no-repeat;
      }
    }
  }

  &:visited {
    color: #404040;
  }
  &:hover, &.hover {
    .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(151, 151, 151, 0.4));
    background-size: 100%;
    .gradient-vertical(#ffffff, #f5f5f5);
    text-decoration: none;
  }
  &:active, &.active {
    color: rgba(64, 64, 64, 0.5);
    background-color: white;
    .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
    background-image: none;
  }
  &[disabled=disabled], &.disabled {
    .opacity(1);
    color: rgba(64, 64, 64, 0.3);
    background-color: white;
    .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
    background-image: none;
    &:active, &.active {
      color: rgba(64, 64, 64, 0.3);
    }
  }
} // .button


.dark .button {
  border: 1px solid #171d23;
  .box-shadow(inset 0 0 0 1px rgba(255, 255, 255, 0.1));
  background-size: 100%;
  .gradient-vertical(#777f88, #535c65);
  color: white;

  &.plus {
    border: 1px solid #171d23;
    .box-shadow(inset 0 0 0 1px rgba(255, 255, 255, 0.1));
    .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -2119px no-repeat, #777f88, #535c65);
    &:hover, &.hover {
      border: 1px solid #171d23;
      .box-shadow(0 2px 0 #3a4249, inset 0 0 0 1px rgba(255, 255, 255, 0.1));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -2119px no-repeat, #777f88, #535c65);
    }
    &:active, &.active {
      border: 1px solid #2a353f;
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.6));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -2197px no-repeat, #28323b, #36424f);
    }
    &[disabled=disabled], &.disabled {
      border: 1px solid #171d23;
      .box-shadow(inset 0 0 0 1px rgba(255, 255, 255, 0.1));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 0 -2045px no-repeat, #3b464f, #2d3842);
    }
  }

  &.primary {
    border: 1px solid #171d23;
    .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
    background-size: 100%;
    .gradient-vertical(#4f8aea, #3e67e5);
    color: white;
    &:hover, &.hover {
      border: 1px solid #171d23;
      .box-shadow(0 2px 0 #3b4249, inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#4f8aea, #3e67e5);
    }
    &:active, &.active {
      color: rgba(255, 255, 255, 0.5);
      border: 1px solid #2a353f;
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
      background-size: 100%;
      .gradient-vertical(#1c3159, #254176);
    }
    &[disabled=disabled], &.disabled {
      color: rgba(255, 255, 255, 0.3);
      border: 1px solid #171d23;
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#25426c, #203666);
      &:active, &.active {
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  &:visited {
    color: white;
  }
  &:hover, &.hover {
    border: 1px solid #171d23;
    .box-shadow(0 2px 0 #3a4249, inset 0 0 0 1px rgba(255, 255, 255, 0.1));
    background-size: 100%;
    .gradient-vertical(#777f88, #535c65);
  }
  &:active, &.active {
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid #2a353f;
    .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.6));
    background-size: 100%;
    .gradient-vertical(#28323b, #36424f);
  }
  &[disabled=disabled], &.disabled {
    color: rgba(255, 255, 255, 0.3);
    border: 1px solid #171d23;
    .box-shadow(inset 0 0 0 1px rgba(255, 255, 255, 0.1));
    background-size: 100%;
    .gradient-vertical(#3b464f, #2d3842);
    &:active, &.active {
      color: rgba(255, 255, 255, 0.3);
    }
  }
} // .dark .button


.button:link, [type=button], [type=submit] {
  .button;
}

a.button {
  display: inline-block;
}

.combo-button {
  display: inline-block;
  position: relative;
  .background-clip(padding-box);
  .border-radius(2px);
  margin-bottom: 2px;
  cursor: pointer;

  .button {
    color: white;
    .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
    background-size: 100%;
    .gradient-vertical(#4376cf, #3558c1);
    padding-right: 50px;
    margin-bottom: 0;

    &:active, &.active {
      color: rgba(255, 255, 255, 0.5);
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
      background-size: 100%;
      .gradient-vertical(#274389, #3459a5);
    }
  }

  ul.options {
    display: none;
    position: absolute;
    top: 37px;
    left: 0;
    z-index: 5;
    border: 1px solid #cccccc;
    margin: 0;
    width: 100%;
    .background-clip(padding-box);
    .border-radius(2px);
    background-color: white;
    .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2));
    list-style: none;
    cursor: pointer;

    li {
      font-size: 1.2 * @rem;
      line-height: 34px;
      height: 34px;
      padding: 0 10px;
      margin: 1px;
      &:hover {
        background-color: #3965ce;
        color: white;
      }
    }
  }

  .button:link, [type=button], [type=submit] {
    .button;
  }

  &.open ul {
    display: block;
  }

  &:after {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 30px;
    height: 34px;
    content: "";
    .box-sizing(border-box);
    .border-radius(0 2px 2px 0);
    .background-clip(padding-box);
    .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
    .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -211px no-repeat, #4376cf, #3558c1);
  }
  &:hover, &.hover {
    .box-shadow(0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.1));
    background-size: 100%;
    .gradient-vertical(#4376cf, #3558c1);
    &:after {
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -211px no-repeat, #4376cf, #3558c1);
    }
  }
  &:active, &.active, &.open {
    .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
    background-size: 100%;
    .gradient-vertical(#274389, #3459a5);
    &:after {
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3), -1px 0 rgba(255, 255, 255, 0.1));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -1678px no-repeat, #274389, #3459a5);
    }
  }
  &.disabled {
    .opacity(1);

    .button, .button:link, [type=button], [type=submit] {
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#bccaeb, #a5b9e4);
      color: white;
    }

    &:hover, &.hover {
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#bccaeb, #a5b9e4);
      &:after {
        .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
        .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -211px no-repeat, #bccaeb, #a5b9e4);
      }
    }
    &:active, &.active, &.open {
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#bccaeb, #a5b9e4);
      &:after {
        .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
        .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -211px no-repeat, #bccaeb, #a5b9e4);
      }
    }
    &:after {
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -211px no-repeat, #bccaeb, #a5b9e4);
    }
  }

  .dark & {
    color: white;
    border: 1px solid #171d23;
    .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
    background-size: 100%;
    .gradient-vertical(#4f8aea, #3e67e5);

    &:active, &.active {
      color: rgba(255, 255, 255, 0.5);
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
      background-size: 100%;
      .gradient-vertical(#1c3159, #254176);
    }

    &:after {
      border-width: 1px 1px 1px 0;
      border-style: solid;
      border-color: #171d23;
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
      .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -211px no-repeat, #4f8aea, #3e67e5);
    }

    &:hover, &.hover {
      .box-shadow(0 2px 0 #3b4249, inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#4f8aea, #3e67e5);
      &:after {
        .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
        .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -211px no-repeat, #4f8aea, #3e67e5);
      }
    }
    &:active, &.active, &.open {
      .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3));
      background-size: 100%;
      .gradient-vertical(#1c3159, #254176);
      &:after {
        .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.3), -1px 0 rgba(255, 255, 255, 0.1));
        .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -1678px no-repeat, #1c3159, #254176);
      }
    }

    &.disabled .button {
      .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
      background-size: 100%;
      .gradient-vertical(#25426c, #203666);
      color: rgba(255, 255, 255, 0.3);
      &:hover, &.hover {
        .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
        background-size: 100%;
        .gradient-vertical(#25426c, #203666);
        &:after {
          .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
          .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -1710px no-repeat, #25426c, #203666);
        }
      }
      &:active, &.active, &.open {
        .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1));
        background-size: 100%;
        .gradient-vertical(#25426c, #203666);
        &:after {
          .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
          .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -1710px no-repeat, #25426c, #203666);
        }
      }
      &:after {
        .box-shadow(inset 0 0 0 1px rgba(0, 0, 0, 0.1), -1px 0 rgba(255, 255, 255, 0.1));
        .gradient-image(url('~@bimages/icons-s2b11c90dfb.png') 1px -1710px no-repeat, #25426c, #203666);
      }
    }
  }
} // .combo-button


.segment-button {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 2px;
  cursor: pointer;
  .button {
    float: left;
    margin-left: -1px;
    margin-bottom: 0;
    .border-radius(0);
    &:first-child {
      .border-radius(2px 0 0 2px);
      margin-left: 0;
    }
    &:last-child {
      .border-radius(0 2px 2px 0);
    }
    &.selected {
      background-color: #cbcbcb;
      .box-shadow(inset 0 1px 3px rgba(0, 0, 0, 0.65));
      background-image: none;
      .dark & {
        border: 1px solid #2a353f;
        .box-shadow(inset 0 1px 4px 0 rgba(0, 0, 0, 0.6));
        background-size: 100%;
        .gradient-vertical(#1f272e, #343f4c);
      }
    }
  }
  .button:link, [type=button], [type=submit] {
    .button;
  }
} // .segment-button


.icon-button {
  display: inline-block;
  height: 22px;
  width: 22px;
  text-indent: 22px;
  overflow: hidden;
  line-height: inherit;
  padding: 0;
  opacity: 1;
  .box-shadow(0 0);
  border: none;
  &.mail {
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -66px no-repeat;
    &[disabled=disabled], &.disabled {
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 -88px no-repeat;
    }

    .dark & {
      background: url('~@bimages/icons-s2b11c90dfb.png') 0 0 no-repeat;
      &:hover, &.hover, &:active, &.active {
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -110px no-repeat;
      }
      &[disabled=disabled], &.disabled {
        background: url('~@bimages/icons-s2b11c90dfb.png') 0 -22px no-repeat;
      }
    }
  }
  &:hover, &.hover, &:active, &.active {
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -44px no-repeat;
    .dark & {
      .box-shadow(0 0);
      border: none;
    }
  }
} // .icon-button

//button style with new design: DF-40573
.cp-gray-button
{
  box-sizing: border-box;

/* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 8px;
  width: 120px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D5DBE2;
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
  &:hover {
    background: #F3F6FF;
    border: 1px solid #264BCF;
    box-shadow: none;
  }
  &:disabled, &:disabled:hover{
    background: #fff ;
    border: 1px solid #D5DBE2;
    box-shadow: none;
    color: #999;
    cursor: not-allowed;
  }
}
.cp-blue-button{
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 120px;
  height: 32px;

  background: #264BCF;
  color: #fff;

  border: 1px solid #264BCF;
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;
  &:hover {
    background: #264BCF;
    box-shadow: none;
  }
}