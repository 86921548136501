.appointment-confirmation-outer {
  padding: 40px;
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: 24px;
}

.appointment-confirmation-title {
  color: var(--text-default, #1B2126);
  text-align: center;
  font-family: inherit;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */
}
.appointment-confirmation-list-outer {
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--l-grey-500, #D5DBE2);
  background: var(--card-modals-background, #FFF);
  width: 386px;
}

.appointment-confirmation-lists-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.appointment-confirmation-list-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.appointment-confirmation-consumer-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--text-table-title, #69737D);
  /* Heading/Semibold/S */
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
  }
}

.appointment-confirmation-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%
}

.appointment-confirmation-datetime {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  span {
    color: var(--primary-df-blue-500-default, #264BCF);
    font-family: inherit;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
  }
}

.appointment-confirmation-button {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Primary-500, #264BCF);
  background: var(--Primary-500, #264BCF);
  span {
    min-width: 48px;
    color: var(--surface-background-surface, #FFF);
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }
}

.appointment-confirmation-button:hover {
  box-shadow: none;
  background: #2245bf;
  border-color: #2245bf;
  cursor: pointer;
  span {
    color: white;
  }
}

.appointment-confirmation-confirmed-message {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: var(--success-500-default, #008325);
    /* Body/Semibold/M */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
}

.appointment-confirmation-loader {
  display: none;
  justify-content: center;
  margin-top: 8px;
  align-self: stretch;
  align-items: center;
}

.appointment-confirmation-loading {
  border: 2px solid #ccc;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-top-color: #1ecd97;
  border-left-color: #1ecd97;
  animation: spin 1s infinite ease-in;
}

.appointment-confirmation-toast-thankyou {
  display: inline-flex;
  padding: 10px 42px 10px 20px;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  border-radius: 4px;
  border-left: 4px solid var(--Success-500_Default, #008325);
  background: var(--Success-100_Hover-BG, #E7F8EB);
}

.appointment-confirmation-toast-information-notification {
  color: var(--d-grey-900, #171717);

  /* Body/Semibold/M */
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.appointment-confirmation-toast-error {
  display: flex;
  width: 340px;
  padding: 10px 16px 10px 20px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  border-left: 4px solid var(--error-danger-500-default, #CF2626);
  background: var(--error-danger-100-hover-bg, #FAE9E9);
}

.appointment-confirmation-add-to-calendar-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: var(--text-table-title, #69737D);
  text-align: center;
  /* Body/Button/M/Regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.appointment-confirmation-appointment-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  a.ical, a.google, a.outlook {
    width: 108px;
    height: 32px;
    border: 1px solid #D5DBE2;
    border-radius: 3px;
    display: flex;
    background-repeat: no-repeat;
    background-position: left center;
    background-position-x: 15px;
    font-size: 14px;
    color: #1B2126;
    padding: 7px 20px 5px 36px;
    text-decoration : none;
    align-items: center;
  }
  a:hover {
    background-color: #f3f6ff;
    border-color: #264bcf;
  }
  a.ical    { background-image:url('~@bimages/appointments-iCalendar.svg');       }
  a.google  { background-image:url('~@bimages/appointments-google-calendar.svg');  }
  a.outlook { background-image:url('~@bimages/appointments-outlook-calendar.png'); }
}

.appointment-confirmation-divided-line {
  width: 338px;
  height: 1px;
  background: #F2F2F2;
}


.confConfirmedMessageV2 {
  color: var(--success-500-default, #008325);
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  display:flex;
  flex-direction: row;
}

.confConfirmedMessageV2 .check_mark {
  margin-right: 8px;
}

.confErrorMessageV2 {
  display: flex;
  width: 340px;
  padding: 10px 16px 10px 20px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  border-left: 4px solid var(--error-danger-500-default, #CF2626);
  background: var(--error-danger-100-hover-bg, #FAE9E9);
  margin-top: 8px;
  color: var(--d-grey-900, #171717);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.confErrorMessageV2.hidden {
  display: none;
}

.confBtnV2 {
  width: 338px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #264BCF;
  background: #264BCF;
  color: white;
  justify-content: center;
  font-size: 14px;
  margin-top: 8px;
}

.confBtnV2:hover {
  color: white;
  box-shadow: none;
  background: #2245bf;
  border-color: #2245bf;
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.confThankYouMessageV2 {
  display: flex;
  padding: 10px 16px 10px 20px;
  border-radius: 4px;
  border-left: 4px solid var(--success-500-default, #008325);
  background: var(--success-100-hover-bg, #E7F8EB);
  color: var(--d-grey-900, #171717);
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  visibility: visible;
}

.confThankYouMessageV2.hidden {
  display: none;
}

.appointment-confirmation-single-wrapper {
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 25px;
  padding-top: 25px;
}
