.form_wrap{
  width:100%;
  padding:20px;

  .success-title{
    font-size: 20px;
    margin: 25px 0;
  }
  .success-title + hr{
    margin: 25px 0;
  }
}
.form-body{
  .form-row {
    margin: 0 0 25px;
    position: relative;
    &:last-child{
      margin-bottom: 30px;
    }
  }
}
.form-submit{
  .form-row{
    margin:0 0 30px;
  }
}
.label-title{
  font-family:"HelveticaNeueBold",
  "HelveticaNeue-Bold","Helvetica Neue Bold","HelveticaNeue","Helvetica Neue","Helvetica","Arial",sans-serif;
  font-weight:600;
  font-stretch:normal;
  display:block;
  margin-bottom:4px;
  padding-left:2px;
  &.error {
    color: #d34a2e;
  }
}
.input-long{
  width: 270px;
}

.tooltip {
  position: absolute;
  min-height: 1px;
  .opacity(0);
  display:none;
  &.error {
    display: block;
    .opacity(1);
    &[for="g-recaptcha-response"]{
      z-index: 1;
      left: 10px !important;
    }
  }
  width: 15em;
}
.actual-mileage .tooltip{
  left: 120px;
  top:-20px;
  width:270px;
}