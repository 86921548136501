select#service-requested-form{
  padding: 13px 32px 13px 13px;
}
.blue-button-mixin(){
  .schedule-form-submit {
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    color:#fff;
    font-size:16px;
    border:none;
    outline:none;
    padding:8px 20px;
    display: inline-block;
    background-size: 100%;
    background-color: #3558c1;
    background-image: -moz-linear-gradient(top, #4376cf, #3558c1);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4376cf), to(#3558c1));
    background-image: -webkit-linear-gradient(top, #4376cf, #3558c1);
    background-image: linear-gradient(to bottom, #4376cf, #3558c1);
    &:hover, &:focus {
      cursor:pointer;
      text-decoration:none;
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        color: 0 2px 0 rgba(0, 0, 0, 0.2) inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      }
      &:active {
      color: inset 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      background-image: -moz-linear-gradient(top, #274389, #3459a5);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#274389), to(#3459a5));
        background-image: -webkit-linear-gradient(top, #274389, #3459a5);
        background-image: linear-gradient(to bottom, #274389, #3459a5);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff274389', endColorstr='#ff3459a5', GradientType=0);
    }
  }
}


.business-profile-schedule {
  .business-profile-schedule-widget {
    overflow: hidden;

    @media @mobile {
      width: 320px;
      float: none;
    }
  }
}
.scheduling-message {
  font-weight:300;
  padding:10px;
  margin:20px 20px 0 30px;
  display:inline-block;
  line-height:18px;
  border-left:5px solid @dark-yellow;
  pre{
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

}
.scheduling-message-for-book{
  border: 1px solid #3562CD;
  border-radius: 3px;
  width:94%;
  padding:14px;
}
.appointment-schedule-widget.facebook {
  max-width: 810px;
  min-width: 320px;
  main {
    max-width: 810px;
    min-width: 320px;

    .same-row:last-child .picker__holder{
      right: 100px;
    }
  }
}
.appointment-schedule-widget main.business-schedule {
  margin-top: 0px;
}
#business-appointment-schedule-main, #appointment-request-confirmation-main, .appointment-schedule-widget {
  .services.form-row { margin: 0 10px 10px; }
  .schedule-form-row {
    position: relative;
  }
 .input-title{
    &.error {
        color: #d34a2e;
    }
  }
 .tooltip {
    position: absolute;
    min-height: 1px;
    .opacity(0);
    display:none;
    &.error {
      display: block;
      .opacity(1);
    }
    top: -18px;
    left: 2px;
    width: 16em;
  }
  #appointment-dates{
    .tooltip{
      z-index:1000;
      top:-18px;
    }
    .appointmentTime{
      position: static !important;
      .tooltip {
        position: relative !important;
        top:20px !important;
        p {
          margin: 0;
          padding: 10px;
        }
      }
    }
  }

  @media @mobile {
      float: none;
      .same-row {
        padding-bottom: 20px;
      }
      .input-field .same-row:last-child {
        padding-bottom: 0px;
      }
    }
  width: 100%;
  padding: 20px;
  h2 {
    .helvetica-light;
    font-size:24px;
    padding:0 0 24px 0;
  }
  .hidden{
    display:none;
  }
  .input-field {
    display: inline-block;
  }

  .input-field .same-row {
    float: left;

    @media @mobile {
      float:none;
      display:block;
      #appointment-schedule-last-name,
      #appointment-schedule-first-name,
      #required-date, #optional-date-1, #optional-date-2 {
        width:270px;
      }
    }
  }

  input[type="text"], input[type="email"], textarea {
    border-radius:3px;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    padding:10px;
    font-size:14px;
    height:auto !important;
    &:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border-color:@blue;
    }
    &::-moz-placeholder {
      font-style: normal;
    }
    &::-webkit-input-placeholder {
      font-style: normal;
    }
  }

  #appointment-dates .input-field{
    position: relative;
    min-width: 270px;
    width: 520px;
  }
  .date-field-picker{
    position: absolute;
    left: 249px;
    top: 0;
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
    background: none;
    border: 0;
  }
  .input-title {
    font-family:"HelveticaNeueBold",
    "HelveticaNeue-Bold","Helvetica Neue Bold","HelveticaNeue","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-weight:600;
    font-stretch:normal;
    display:block;
    margin-bottom:4px;
    padding-left:2px;
    &.required-field::after{
      content: "*";
      color: #CF2626;
    }
  }
  .schedule-form-row {
    display:block;
    margin:0 0 18px;
    clear: both;
  }
  .schedule-form-label {
    display: block;
    padding: 5px 0 5px 2px;
    font-style:italic;
  }
  .schedule-form-input-long {
    width: 270px;
  }
  textarea.schedule-form-input-long {
    width:519px;
    @media @mobile {
      width:270px;
    }
  }


  .blue-button-mixin();

  .appointment-request-confirmation-row {
    margin:10px 30px;
  }
  .appointment-request-confirmation-label {
    font-weight: 600;
    color: #226BA5;
    min-width: 100px;
    width: 180px;
  }
  .appointment-request-confirmation-detail{
    color: #7E7E7E;
    padding: 5px;
  }
  hr {
    width:100%;
    margin:20px 0;
    border:none;
    border-bottom:1px solid @light-gray;
  }
  .appointment-request-confirmation-messsage {
    font-weight: 800;
    font-size: 18px;
    line-height: 1.1;
    color: #7E7E7E;
  }
  .business-schedule-appointment-services-section{
    select{
      margin-bottom:0px;
    }
    @media screen and (max-width:1024px) {
      float:none;
    }

  }
  .appointment-providers{
    select{
      margin-bottom:0px;
    }
    @media screen and (max-width:1024px) {
      float:none;
      margin:0px;
    }
  }
  .availability-calendar {
    h1 {
      margin-bottom:20px;
      padding:0;
      border: none;
      box-shadow: none;
      webkit-box-shadow:none;
    }
    .availability-calendar-main {
      margin-bottom: 10px;
      table {
      margin:0;
      }
    }
    @media screen and (max-width:640px) {
      margin:0 -20px !important;
    }
  }
}

#business-appointment-schedule-main {
  input.read-only {
    background-color: #f1f1f1;
    color: #8d8d8b;
  }
  input.read-only:focus {
    box-shadow: none;
    border-color: #ccc;
  }
}


#appointment-request-confirmation-main {
    font-size: 14px;
  }

#appointment-request-form {
  .schedule-form-row {
    label[for="comment"] {
      width: 330px;
      top: -30px;
    }
  }
}
