.error-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #F3F6FF;
  border: 20px solid #F3F6FF
}

.content-min-height{
  min-height: 300px;
}