.transparent-background{
  background: transparent;
}

.facebook {

  width: 810px;
  min-width: 810px;
  max-width: 810px;

  main {
    width: 810px;
    min-width: 810px;
    max-width: 810px;
  }

  .business-profile-about-or-reviews{
    padding: 50px;
  }

  .errorMessage {
    color: red;
  }

  .business-schedule{
    width: 770px;
    background-color:white;
  }

  .authorizationPage{
    padding: 40px;


    h1{
      padding: 12px 0 14px 0;
      text-align: center;
    }
    h3 {
      padding: 12px 0 14px 0;
      text-align: center;

      font-family: "HelveticaNeueLight", "HelveticaNeue-Light", "Helvetica Neue Light", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      font-weight: 300;
      font-stretch: normal;
      font-size: 18px;
    }
  }

  #loginForm {

    width: 100%;

    label {
      margin: 10px 0;
      width: 65px;
      float: left;
    }

    input#username, input#password{
      outline: none;
      height: 40px;
      border: 1px solid #cccccc;
      padding: 0 6px;
      -moz-background-clip: padding-box;
      -webkit-border-radius: 2px;
      -webkit-background-clip: padding-box;
      -moz-border-radius: 2px;
      -moz-background-clip: padding;
      border-radius: 2px;
      background-clip: padding-box;
      margin-left: 30px;
      font-size: 16px;
    }

    #username{
      margin-bottom: 15px;
    }
    #password{
      margin-bottom: 5px;
    }

    .usernamePassword {
      padding: 20px 0 20px 0;
      font-size: 16px;
      width: 50%;
      margin: 0 auto;
      .forgotPassword{
        float: right;
        font-size: 12px;
        padding-right: 30px;
      }

    }

    .field {
      width: 240px;
    }

    .terms {
      float: left;
      padding: 0;
      margin: 0;
      vertical-align: bottom;
      position: relative;
      margin-right: 15px;
      *overflow: hidden;
    }

    .termsText {
      width: 30%;
      margin: 15px auto 0 auto;
    }

    .submitOuter{
      width: 10%;
      margin: 25px auto 0 auto;
    }

    .inputsubmit {
      margin: 0 auto;
    }

  }

  #installSuccess{
    margin-top: 0px;
    text-align: center;

    .appLogo {
      float: left;
      margin-top: 10px;
    }

    .appInstall {
      float: left;
      margin-right: 5px;
      margin-top: 35px;
      font-size: 14px;
    }

    .otherApps{
      margin-left: 178px;
    }

    h2 {
      text-align: center;

      font-family: "HelveticaNeueLight", "HelveticaNeue-Light", "Helvetica Neue Light", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      font-weight: 300;
      font-stretch: normal;
      font-size: 22px;
    }

  }
}

.masthead-facebook {
  width: 100%;
  background-color: #1e4164;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
  min-width: 320px;
  max-width: 960px;
  padding: 10px 25px 10px 25px;
  line-height:normal;

  img {
    padding: 0 8px 1px 0;
    vertical-align: text-bottom;
  }
  h1 {
    .helvetica-light;
    .box-shadow(none);
    border: none;
    padding: 4px 0 4px 0;
    display: inline-block;
    color: #FFFFFF;
    @media @mobile {
        font-size:2.5 * @rem;
    }
  }
  a {
    vertical-align: bottom;
  }
}

.business-card-schedule-button-facebook {
  float: right;
  padding: 3px 0 0 0;
  a {
    .helvetica;
    height: 2em;
    background-color: #f0640f;
    border-radius: 2px;
    color: #FFFFFF;
    line-height: 2em;
    text-align: center;
    font-size: 1.7 * @rem;
    font-style: normal;
    display: inline-block;
    width: 225px;
    margin: 0 auto;
    &:hover {
      text-decoration: none;
    }
  }

  @media print {
    display: none;
  }
}


.facebook-reviews{
  border: none;
  margin-top: 0px;

  .business-rating {
    padding-bottom: 20px;
    float: left;
    margin-left: 50px;
    padding-top: 35px;

    span {
      line-height: 1.3;
      display: inline-block;
      & + span {
        font-size: 2 * @rem;
        display: block;
      }
    }
  }

  .satisfaction-container {
    padding-bottom: 20px;
    float: right;
    text-align: center;
    margin-right: 50px;
    padding-top: 20px;

    .satisfaction-score {
      font-size: 3.4 * @rem;
      font-weight: bold;
      line-height: 1.3;
      display: inline-block;
      color: @dark-green;
      & + span {
        font-size: 2 * @rem;
        display: block;
      }
    }
  }
}

.facebook-no-appointments {
  border: none;
  .message {
    margin: 60px;
    h1 {
      .helvetica-light;
      font-size: 2.5 * @rem;
      text-align: center;
      border: none;
      box-shadow: none;
      line-height: 25px;
    }
  }
}

.facebook-coupons {
  border: none;
  margin-top: 0px;
  .facebook-coupon-content {
    background-color: #365ebf;
    color: #FFFFFF;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin: 40px;
    -webkit-box-shadow:0 4px 8px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:0 4px 8px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow:0 4px 8px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    h1 {
      .helvetica-light;
      font-size: 2.5 * @rem;
      text-align: center;
      border: none;
      box-shadow: none;
      padding: 30px 20px 10px 20px;
    }
    h2 {
      .helvetica-light;
      font-size: 1.5 * @rem;
      text-align: center;
      padding: 10px 20px 20px 20px;
    }
    .business-card-schedule-button-facebook {
      float: none;
      text-align: center;
    }
    h3 {
      .helvetica-light;
      font-size: 1.3 * @rem;
      text-align: center;
      font-style: italic;
      padding: 20px 20px 20px 20px;
    }
    .coupon-photo-facebook {
      text-align: center;
      padding-top: 20px;
    }
  }
  .facebook-no-coupon-content {
    color: #404040;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin: 60px;
    h1 {
      .helvetica-light;
      font-size: 2.5 * @rem;
      text-align: center;
      border: none;
      box-shadow: none;
      padding: 30px 20px 10px 20px;
    }
    h2 {
      .helvetica-light;
      font-size: 1.5 * @rem;
      text-align: center;
      padding: 10px 20px 20px 20px;
    }
    .business-card-schedule-button-facebook {
      float: none;
      text-align: center;
      padding: 0 0 30px 0;
    }
  }
}

.business-schedule-facebook {
  border: none;
  margin-top: 0;
  #page {
    border: none;
  }
}

.facebook-error {
  border: none;
  margin-top: 0;
}

.appProfileImg{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#facebook-footer {
  background-color: #1e4164;
  color: #FFFFFF;
  padding: 8px 20px;
  vertical-align: bottom;
  a {
    color: #0098cd;
    margin: 5px 0 0 0;
    vertical-align: text-bottom;
  }
  .intuit-local{
    vertical-align: middle;
    display: inline-block;
    height: 100%;
    img {
      vertical-align: middle;
    }
    img#powered-gray{
      height:25px;
    }
  }
  .app-link {
    float: right;
    padding-top: 10px;
  }
  #demoLink{
    float: right;
    padding-left: 10px;
    margin: 0 0 0 0;
  }
}

.clear{
  clear: both;
}
