#recall_container {
  background-color: #FFFFFF;
  .recall {
    margin-top: 106px;
  }
  #page {
    .main-content {
      display: flex;
      flex-direction: column;
      min-height: 92vh;
    }
  }
  .footer-v2 {
    clear: both;
    background-color: rgba(248, 250, 252, 0.79);
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 16px;
    line-height: 16px;
    .footer-links-v2 {
      a {
        color: #1F97DC;
        font-size: 14px;
        font-weight: 400;
        line-height: 15.52px;
      }
      a:last-child:before {
        content:"\00b7";
        padding-right: 8px;
        font-weight: bolder;
        color: #333132;
        font-size: 14px;
        margin-left: 7px;
      }
      a:hover {
        color: #3965ce;
        text-decoration: underline;
      }
    }
    .recall-footer-copyright {
      color: #333132;
      font-size: 14px;
      font-weight: 400;
    }
  }
}