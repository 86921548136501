.unsubscribe-main{
  display: flex;
  flex: 1;
  flex-direction: column;
  .display-none{
    display: none !important;
  }
  .masthead{
    max-width: 640px !important;
    h1{
      font-size: 24px !important;
      margin-bottom: 8px;
    }
  }
  .main-section{
    //min-height: 300px;
    p{
      font-size: 14px;
      text-align: center;
      padding-bottom: 0;
      margin-bottom: 25px;
      &.submit-row {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        input {
          height: 32px;
          line-height: 32px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
