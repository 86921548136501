// Import this to use the Harmony theme.
//
// You only need to include this once to get all the Harmony styles used by
// consumer properties.  Application-specific styles (e.g nav) not included.
//
// You must include this before any other CSS styles, since it includes CSS
// reset.

@charset "utf-8";

@import "reset";
@import "util";
@import "colors";
@import "typography";
@import "buttons";
@import "controls";
@import "layout";


a {
  text-decoration: none;
  &:link,
  &:visited {
    color: #3965ce;
  }
  &:hover {
    color: #3965ce;
    text-decoration: underline;
  }
  &:active {
    color: #2c4d9c;
  }
  &.disabled {
    text-decoration: none;
    .opacity(.4);
    cursor: default;
  }
}
input[type=button],
input[type=submit],
input[type=reset] {
  margin: 0;
}
ol {
  list-style: decimal;
  padding-left: 20px;
}
