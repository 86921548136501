// Common layout elements for consumer properties.
//
//
//  body
//    header   - Standard header, does not scroll with page
//      .title - Same as page title
//    main     - Styled with page name (e.g. .business-profile)
//      #page  - Contains everything on the page, except footer
//    footer   - Standard page footer

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  src: url("~@browser/assets/e/fonts/proximanova-light-webfont.woff");
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: url("~@browser/assets/e/fonts/proximanova-regular-webfont.woff");
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 500;
  src: url("~@browser/assets/e/fonts/proximanova-semibold-webfont.woff");
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  src: url("~@browser/assets/e/fonts/proximanova-bold.woff");
}

// Up to 480px wide, we treat this as mobile device (iPhone, Android, etc)
@mobile: ~"only screen and (max-width: 640px)";
// This targets iPad specifically.
@ipad: ~"(min-device-width: 641px) and (max-device-width: 1024px)";


body, header, main, footer {
  width: 100%;
  max-width: 1280px;

  @media @mobile {
    max-width: 640px;

    #widget-outer {
      padding: 0
    }
  }
}
body {
  margin:0 auto;
  background: @background-color;
}
* {
  .box-sizing(border-box);
}


// Embedded in iframe.
body.embedded {
  margin: 0;
  padding: 0;
  min-width: 100%;
  width: 100%;
  height: 100%;
  @media @mobile {
  }
}


// Many pages are split into main-content (expandable) and side-column (320px).
// These two are stacked on top of each other in mobile view.
main {
  border-left: 1px solid @gray;
  border-right: 1px solid @gray;
  border-bottom: 1px solid @gray;
  display: block;
}
#page {
  width: 100%;
  overflow: hidden;
  border-spacing: 0px;
  border-collapse: collapse;
  display: table;

  @media @mobile {
    display: block;
    border: none;
  }

  .main-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;

    @media @mobile {
      display: block;
    }
  }

  .side-column {
    display: table-cell;
    width: 320px;
    vertical-align: top;

    background-color: @lighter-slate;

    .business-card {
      border: none;
    }

    @media @mobile {
      display: block;
      border: none;
      width: 100%;
    }
  }

}


// Unique layout for error page: there is no header, but there is a masthead.
// The main content is centered on the page in H1-sized font.
body.error-page {
  margin-top: 0;
  main {
    margin-top: 0;
  }
}

.error-page-message {
  padding: 20px;
  max-width: 640px;
  margin: 10% auto;

  .helvetica;
  .helvetica-bold;
  font-size: 2.6 * @rem;
  line-height: 1.2;
}

// Refreshed layout styles
.refreshed-layout {
  background-color: #fff;
  font-family: "ProximaNova", "HelveticaNeueRegular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  input {
    &[type="text"] {
      border: 1px solid #cbcbd2;
      box-shadow: inset 0 3px 2px 0 rgba(239, 239, 241, 0.7);
      border-radius: 4px;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1.1px;
      text-align: center;
      height: 44px;
      text-align: center;
      width: 100%;
      &::-webkit-input-placeholder {
        color: #d8d8d8;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 1.1px;
        text-align: center;
      }
      &:focus {
        box-shadow: none;
      }
      &.error {
        border: 2px solid #fc9e9a;
        box-shadow: inset 0 2px 3px 0 rgba(231, 212, 212, 0.74), 0 0 4px 0 rgba(255, 114, 103, 0.31);
      }
    }
    @media (min-width: 641px) {
      height: 36px;
      max-width: 350px;
    }
  }
  .input-error-message {
    color: #ed4d41;
    margin-top: 7px;
    font-size: 13px;
    letter-spacing: 0.3px;
    font-weight: 300;
  }
  .notification-bar {
    padding: 10px;
    text-align: center;
    letter-spacing: 0.8px;
    font-size: 13px;
    font-weight: 300;
    &--error {
      background-color: #f4584c;
      color: #fff;
    }
  }
  .action-button {
    background-image: linear-gradient(110deg, #0089ff, #2d34e5);
    width: 100%;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-family: "ProximaNova", "HelveticaNeueRegular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 300;
    height: 44px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding: 11px 0;
    @media (min-width: 641px) {
      max-width: 250px;
      padding: 10px 0;
    }
  }
  #page {
    border-top: none;
    flex: 1;
    flex-direction: column;
    display: flex;
  }
  .masthead {
    background-color: #fff;
    border: 0;
    text-align: center;
    div {
      padding: 8px 24px 0;
      h1 {
        color: #000;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.7px;
        margin-top: 12px;
      }
    }
    .header-image {
      width: 100%;
    }
  }
  .side-column {
    border-top: 1px solid #d5dade;
    display: flex !important;
    flex: 1;
    width: 100%;
    background-color: #f4f6f8;
    padding: 10px 12px;
    @media (min-width: 641px) {
      flex: 0 0 350px;
      padding: 0;
    }
  }
  .main-wrapper{
    .masthead{
      max-width: 640px !important;
      h1{
        font-size: 24px !important;
        margin-bottom: 15px;
      }
    }
    .main-section p{
      font-size: 14px;
      text-align: center;
    }
  }
  .main-content {
    overflow: auto;
  }
  .business-card {
    background-color: #fff;
    border: 1px solid #dfe3e6;
    box-shadow: 0px 0px 5px 1px rgba(218, 225, 230, 0.4);
    font-size: 16px;
    min-width: 0;
    @media (min-width: 641px) {
      box-shadow: none;
      border-left: 1px solid #e5ecf2 !important;
      padding: 40px 20px 20px 40px;
      width: 100%;
    }
    &-header {
      border-bottom: 1px solid #d5dade;
      padding: 0;
    }
    &-logo {
      display: none;
      @media (min-width: 641px) {
        display: block;
        margin: 0 10px 10px 0;
      }
    }
    &-name {
      margin-bottom: 5px;
    }
    &-vertical {
      margin-bottom: 8px;
    }
    &-rating {
      margin-bottom: 8px;
    }
    &-icon {
      display: none;
    }
    &-address {
      margin-bottom: 14px;
      padding: 0;
    }
    &-address-locality {
      margin-top: 3px;
    }
    &-contact {
      border-bottom: 1px solid #d5dade;
      &:last-child {border-bottom:none;}
      .phone, .email, .website {
        margin-bottom: 14px;
        padding: 0;
      }
    }
    &-hours {
      margin-top: 15px;
    }
  }
  .confirmation-box {
    background-color: #ebffe9;
    border: 1px solid #c6ebc3;
    border-radius: 5px;
    font-size: 16px;
    padding: 15px 20px;
    text-align: left;
    @media (min-width: 641px) {
      padding: 15px 35px;
      margin-bottom: 40px;
      text-align: center;
    }
    &__body {
      line-height: 20px;
    }
  }
  .prompt-box {
    background-color: #f1f2f6;
    border-radius: 6px;
    font-size: 16px;
    padding: 20px 24px;
    position: relative;
    text-align: left;
    @media (min-width: 641px) {
      text-align: center;
    }
    &__headline {
      font-weight: 600;
      margin-bottom: 14px;
      text-align: center;
    }
    &__body {
      position: relative;
      text-align: center;
      @media (min-width: 641px) {
        line-height: 22px;
      }
    }
    &__button {
    }
  }
  @media (min-width: 641px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 1280px;
    #page {
      flex-direction: row;
    }
    .masthead {
      margin: auto;
      max-width: 540px;
      div {
        padding-top: 70px;
        .fa-blue-bg {
          width: 72px;
          height: 72px;
          border-radius: 50%;
          background: #F3F6FF;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
        h1 {
          font-size: 26px;
          margin-top: 25px;
        }
      }
    }
    .business-card {
      &-name {
        color: #222222;
        font-size: 18px;
        font-weight: 400;
      }
      &-vertical {
        font-size: 16px;
        color: #1d3f65;
      }
    }
  }
}
// Global styles to change
// .business-profile-header {
//   background-image: linear-gradient(268deg, rgba(48, 34, 173, 0), rgba(48, 35, 173, 0.01), rgba(46, 129, 197, 0.64)), linear-gradient(92deg, #073cb8, #060051), linear-gradient(#2f405b, #2f405b);
//   max-height: 44px;
//   padding: 3px;
// }

@font-face {
  font-family: 'patient-portal';
  src: url("~@browser/assets/e/fonts/patient-portal.woff");
}