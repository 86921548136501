// Business profile consists of four rows:
//
//   Standard header
//   Business profile top
//   Business profile bottom
//   Standard footer
//
// Business profile top contains the business card and map.
//
// Business profile bottom contains the navigation links, reviews/about
// views, and the network module.


.business-profile {
}

// The top part of the business profile contains the business card and map.
//
// Business card comes first and will show above map in mobile view.
//
// In full view, map is on the left, business card on the right.  The business
// card is 320px wide and dictates the height of the container.  To make that
// possible, we float it right, within a container that floats left.  CSS magic.
//
// The map takes over the remaining width and available height.
.business-profile-top {
  float: left; // dark magic CSS that helps with the equal height columns
  width: 100%;
  position: relative;
  overflow: hidden;

  .business-profile-map {
    position: absolute;
    overflow: hidden;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 320px; // business card on the right is 320px

    @media @mobile {
      position: relative;
      height: 180px; // 16:9 ratio when screen is 320px wide
      width: 100%;
    }
  }

  .business-card {
    float: right;
    @media @mobile {
      float: none;
    }
  }
}


// The map consists of two layers:
// - The bottom layer is the Google maps canvas element
// - The top layer is a link that opens to the Google/Apple map,
//   hidden on mobile
.business-profile-map {
  @media @mobile {
    border: none;
  }

  a {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 3;
    overflow: hidden;
    // Phone does not accept clicks on map (fat fingers)
    @media @mobile {
      display: none;
    }
  }

  #business-profile-map-canvas {
    position: absolute;
    cursor : pointer !important;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}

// some styles might need !important as suffix so it  does not get overridden by maps
#customerLocationControlDiv {
  padding: 5px;
}

.mapControl {
  cursor : pointer;
  width : 200px;
  height : 50px;
  background-color : #e3eaf3;
  border-style : solid;
  border-width : 2px;
  text-align : center;
  padding-top : 15px;
}

#customerLocationsMapControl {
  display: block;
}

#defaultMapControl {
  display: none;
}

.controlText {
  font-family: Arial,sans-serif;
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
  color: #6600FF;
}

// The bottom part of the business profiles contains the review and about views,
// one of which is visibile, and navigation links.  It also contains the network
// module.
//
// The navigation links, if present, take the entire width of the profile.
//
// In mobile view, the navigation links are stacked on top of the reviews/about
// view, which is stacked on top of the network module.
//
// In full view, the network module is 320px on the right (aligned with business
// card), and the reviews/about section occupies the remaining width.
//
// This section has to be just tall enough to accomodate whichever component
// (reviews, about or network module) is taller but no more, which is why it is
// floated left, and the reviews/about container and network module containers
// are floated within.
.business-profile-bottom {
  float: left; // dark magic CSS that helps with the equal height columns
  width: 100%;
  position: relative;
  overflow: hidden; // necessary to prevent scrolling within bottom profile
  @media @mobile {
    float: none;
  }

  @media @mobile, print {
    border: none;
  }
}


// Navigation links for moving between reviews and about section, appear just
// above these sections.
.business-profile-navigation {
  margin:18px 20px 0 20px;
  border-bottom:1px solid @light-gray;
  -moz-box-shadow: 0 4px @lighter-gray;
  -webkit-box-shadow: -0 4px @lighter-gray;
  box-shadow: 0 4px @lighter-gray;
  padding:17px 0;
  @media @mobile {
    margin:18px 0 0 0;
    padding:17px 0 0 0;
  }
  a {
    font-size: 20px;
    padding:0 17px 14px 17px;
    &:hover {
      border-bottom:3px solid @lighter-slate;
      color:@dark-blue;
      text-decoration:none;
    }
    &.disabled {
      color: #666666;
      opacity:1.0;
      border-bottom:3px solid @blue;
    }
    @media @mobile {
      display:inline-block;
      width:50%;
      text-align:center;
    }
  }
}
#business-profile-navigation-reviews {

}
#business-profile-navigation-about {
  border-left:1px dotted @gray;
}


// Wrap the about/reviews section in one element, taking over two left columns,
// so we can easily position network module on the right most column, drop both
// below the navigation links, and make the content of the bottom half extend
// dynamically to match the height of visible content.
.business-profile-about-or-reviews {
  float: left;
  padding-right: 320px;
  width: 100%;
  overflow: hidden; // prevents about view from sliding under network module

  @media @mobile {
    float: none;
    padding: 0;
  }
}


// These sections are defined in their owen stylesheets.  Here we just deal with
// the common behavior of sliding them in & out of view.
//
// To hide a section out of view, add the .hidden class.  This will cause a
// fancy GPU accelerated transition on modern browsers.
#business-profile-reviews,
#business-profile-about {
  .transition(transform 250ms);

  // A hidden section will slide out of the way, but we still have to make it
  // invisible to the DOM otherwise it gets read by screen reader and
  // highlighted by in-page search.  We also have to make it invisible to the
  // layout, otherwise it stretches the bottom of the page, hence the zero
  // height.
  &.hidden {
    visibility: hidden;
    height: 0px;
    @media print {
      visibility: visible;
      height: auto;
    }
  }

  @media print {
    margin: 20px 0;
  }
}
#business-profile-reviews.hidden {
  .translate(-640px, 0);
  @media mobile {
    .translate(-480px, 0);
  }
  @media print {
    .translate(0, 0);
  }
}
#business-profile-about.hidden {
  .translate(960px, 0);
  @media mobile {
    .translate(480px, 0);
  }
  @media print {
    .translate(0, 0);
  }
}


// Network module
.business-profile-network-module {
  width: 320px;
  float: left;
  margin-left: -320px;

  @media @mobile {
    border-top: 1px solid @gray;
    float: none;
    width: 100%;
    margin: 0;
  }
  @media print {
    display: none;
  }
}
