#page {
  border-top: 1px solid @light-slate;
}
.search-page {
  .helvetica;
  &, main {
    margin-top: 0;
  }

  h1 {
    text-align: center;
    font-size: @rem * 4;
    .helvetica-bold;
    border: none;
    box-shadow: none;
    margin-top: 50px;
  }
  h2 {
    .helvetica-regular;
    text-align: center;
    font-size: @rem * 3;
    line-height: 1.1;

    em {
      color: @slate;
      .helvetica-bold;
      font-style: normal;
    }
  }

  .search-page-form {
    margin: 2em auto 20em auto;
  }
}
.side-column {
  width: 307px;
}
.search-page-results {
  .no-results {
    margin:0 120px;
  }
  #map-container {
    @media @mobile {
      width: auto;
    }
    width: 320px;
    height: 320px;
    position: relative;
    background: #eee url(~@bimages/map-loader.gif) no-repeat center center;
    #expand-map {
      @media @mobile {
        display: none;
      }
      background-color: @light-slate;
      position: absolute;
      bottom: 0px;
      height: 24px;
      width: 104px;
      //margin-left: -52px;
      .border-radius(0 3px 0 0);
      display: block;
      line-height: 24px;
      display: block;
      font-size: 12px;
      font-weight: bold;
      a {
        color: #666;
        display: block;
        padding: 0 6px 0 26px;
        background: url(~@bimages/icon-expand.png) no-repeat 6px center;
        .border-radius(0 2px 0 0);
        &:hover {
          background-color: #fff;
          text-decoration: none;
        }
        &:active {
          .box-shadow(inset 0 1px 3px rgba(0,0,0,0.3));
        }
      }
    }
    &.expanded #expand-map {
      width: 112px;
      a {
        background-image: url(~@bimages/icon-contract.png);
      }
    }
  }
  .search-page-form {
    margin: 0 auto;
  }
  #results-header {
    padding: 12px 20px;
    font-size: @rem * 1.5;
  }
  #results-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .result {
    border-top: 1px solid #ccc;
    padding: 20px;
    font-size: 12px;
    .contact { display:block; }
    .b-info {
      display:inline-block;
      margin-left:60px;
      p {
        padding:0 0 10px 0;
      }
    }
      .special-offer {
        color: #999;
        background: url(~@bimages/icon-coupon.png) no-repeat center left;
        display:block;
        padding-left:18px;
        margin-top:4px;
      }
      .rating {
        overflow:hidden;
        margin:3px 0 0 0;
      }
      .stars-wrap {
        background: url(~@bimages/rating.png) no-repeat right -16px;
        height: 16px;
        width:80px;
        float:left;
      }
      .stars {
        background: url(~@bimages/rating.png) no-repeat 0 0;
        height: 16px;
      }
      .amount { float:left; margin-left:5px; }
      .is45 {background-position: 0 -16px;}
      .is4 {background-position: 0 -32px;}
      .is35 {background-position: 0 -48px;}
      .is3 {background-position: 0 -64px;}
      .is25 {background-position: 0 -80px;}
      .is2 {background-position: 0 -96px;}
      .is15 {background-position: 0 -112px;}
      .is1 {background-position: 0 -128px;}
      .is05 {background-position: 0 -144px;}
      .is0 {background-position: 0 -160px;}
      .logo {
        position:absolute;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size:cover;
      }
      .bname {
        font-size: 16px;
        line-height: 1.3em;
        display:block;
      }
      .category {
        color: #999;
        font-size: 12px;
        font-weight: normal;
      }
      .appointment {
        float:right;
        display:inline-block;
        @media @mobile {
          float:none;
          display:block;
          margin:0 0 10px 0;
        }
      }
      .book {
        display:block;
        text-align:center;
        background: @light-yellow;
        color:@orange;
        padding: 8px 15px;
        font-size: 12px;
        border:1px solid @yellow;
        border-radius:2px;
      }
  }
  #results-pagination {
    margin-bottom: 20px;
    background-color: #FFF;
    border-top: 1px solid #ccc;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    .clearfix;
    height:24px;
    position: relative;
    .pages {
      position: absolute;
      top: 0;
      width: 460px;
      left: 50%;
      margin-left: -230px;
      a {
        display: inline-block;
        padding: 0 4px;
        &.current {
          color: #333;
          font-weight: bold;
        }
      }
    }
    .prev, .next {
      display: block;
      padding: 0 10px;
      color: #333;
      font-weight: bold;
      background-image: url(~@bimages/page-arrows.png);
      background-repeat: no-repeat;
      &:hover {
        text-decoration: none;
        background-color: #fff;
      }
      &:active {
        .box-shadow(inset 0 1px 2px rgba(0,0,0,0.2));
      }
    }
    .prev {
      float: left;
      padding-left: 20px;
      background-position: 8px -48px;
      border-right: 1px solid #ccc;
      .border-radius(0 0 0 3px);
      &.disabled {
        color: #aaa;
        background-position: 8px -72px;
      }
    }
    .next {
      float: right;
      padding-right: 20px;
      background-position: 42px 0px;
      border-left: 1px solid #ccc;
      .border-radius(0 0 3px 0);
      &.disabled {
        color: #aaa;
        background-position: 42px -24px;
      }
    }
  }
  #filters {
    width: 307px;
    padding: 0px 20px;
    color: #555;
    margin-top:10px;
    font-size: 12px;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    label {
      font-weight: normal;
      font-size: 1.2 * @rem;
    }
    input[type=checkbox] {
      margin-right: 4px;
    }
    input[type=checkbox], label {
      display: inline-block;
      line-height: 3 * @rem;
      vertical-align: middle;
    }
    h3 {
      font-size: 13px;
      text-transform: uppercase;
      margin: 0;
      line-height: 16px;
      cursor: pointer;
      padding-top: 0;
      padding-bottom: 10px;
    }
    .selected {
      padding-left: 28px;
      background: url(~@bimages/filter-check.png) no-repeat 16px 6px;
    }
    .filters {
      padding-left: 6px;
    }
    .term {
      display: none;
      &.show {
        display: list-item;
      }
    }
    .filter {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &.expanded {
        h3 {
          background-position: 0 -16px;
        }
      }
    }

    .toggle-filters {
    }
  }
}
.search-page-form {
  display: block;
  max-width: 760px;
  padding: 0 20px;
  margin: 2em auto 20em auto;
  .clearfix;
  @media @mobile {
    margin: 20px auto;
  }

  label {
    display: block;
    margin-bottom: 4px;
    .helvetica-regular;

    em {
      .helvetica-light;
      font-style: normal;
    }
  }
  input {
    display: block;
    font-size: @rem * 2;
    height: @rem * 4;
    width: 100%;
  }
  .search-page-form-field {
    display: block;
    min-width: 280px;
    padding: 10px 4px;
    float: left;

    @media @mobile { width:100%; }
  }
  .search-page-form-submit {
    display: block;
    width: 120px;
    padding: 10px 4px;
    float: left;
    input {
      .helvetica;
      .button;
      .button.primary;
      font-size: @rem * 2;
      height: @rem * 4;
    }
    @media @mobile {
      width: 100%;
      label {
        display: none;
      }
      input {
        margin: 0 auto;
        width: 120px;
      }
    }
  }
}


main.search-page-results{
  .leaflet-control-container{
    z-index:9
  }
  .my-div-icon{
    min-width: 26px;
    min-height: 34px;
    background: url('~@browser/assets/e/images/pin.png');
    transform-origin: 50% bottom;

  }
  .my-div-icon::after{
    position: absolute;
    color: white;
    left: 10px;
    top: 4px;
    font-size: 10px;
    font-weight: bold;
  }
  .my-div-icon:nth-child(n)::after{
    content:'1';
  }
  .my-div-icon:nth-child(2)::after{
    content:'2';
  }
  .my-div-icon:nth-child(3)::after{
    content:'3';
  }
  .my-div-icon:nth-child(4)::after{
    content:'4';
  }
  .my-div-icon:nth-child(5)::after{
    content:'5';
  }
  .my-div-icon:nth-child(6)::after{
    content:'6';
  }
  .my-div-icon:nth-child(7)::after{
    content:'7';
  }
  .my-div-icon:nth-child(8)::after{
    content:'8';
  }
  .my-div-icon:nth-child(9)::after{
    content:'9';
  }
  .my-div-icon:nth-child(10)::after{
    content:'10';
  }
}
