// Mixins for the main text font (Helvetica Neue) and the main numeric font (PF
// Din).
//
// Also applies type selection to body, headers, input and introduces .numeric
// class.


// Unfortunatley IE8 doesn't support rem units.
@rem: 10px;


html {
  // The standard browser default font is typically set to 16 pixels. Scaling the HTML element down to 10 pixels
  // allows us to use rem units to approximate pixel sizing, while keeping relative sizing. (e.g., 1.2rem = 12px)
  font-size: 62.5%;

  // Hide body while fonts are loading.
  // https://github.com/typekit/webfontloader/blob/master/docs/EVENTS.md
  //&.wf-loading {
  //  visibility: hidden;
  //}
}


// Use Helvetica Neue for the main text body.  These mixins apply helvetica at
// three different weights, and italics for emphasis.
.helvetica() {
  font-size: 1.8 * @rem;
  line-height: 2 * @rem; // 1.11
}
.helvetica-regular() {
  font-family: "HelveticaNeueRegular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-stretch: normal;
}
.helvetica-light() {
  font-family: "HelveticaNeueLight", "HelveticaNeue-Light", "Helvetica Neue Light", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-stretch: normal;
}
.helvetica-bold() {
  font-family: "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-stretch: normal;
}
.helvetica-italic() {
  font-family: "HelveticaNeueItalic", "HelveticaNeue-Italic", "Helvetica Neue Italic", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: italic;
}


// Use PF Din for numbers that stand out (amounts, totals, etc).  These mixins
// apply PF Din at three different weights.
.pfdintextpro() {
  font-size: 6 * @rem;
  line-height: 6.2 * @rem;
}
.pfdintextpro-regular() {
  font-family: "PFDinTextProRegular", "PFDinTextPro-Regular", "PFDinTextPro", "PF DinText Pro", sans-serif;
  font-family: "Lato";
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: -0.025em;
}
.pfdintextpro-light() {
  font-family: "PFDinTextProLight", "PFDinTextPro-Light", "PF DinText Pro Light", "PFDinTextPro", "PF DinText Pro", sans-serif;
  font-family: "Lato";
  font-weight: 300;
  font-stretch: normal;
  letter-spacing: -0.025em;
}
.pfdintextpro-bold() {
  font-family: "PFDinTextProBold", "PFDinTextPro-Bold", "PF DinText Pro Bold", "PFDinTextPro", "PF DinText Pro", sans-serif;
  font-family: "Lato";
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: -0.025em;
}


body {
  .helvetica-regular;
  font-size: 1.4 * @rem;
  line-height: 1.8 * @rem; // 1.285714286 x
  color: @body-color;

}
header {
  font-size: 1.4 * @rem;
}
h1 {
  .helvetica-light;
  font-size: 2.6 * @rem;
}
h2 {
  font-size: 2 * @rem;
}
h3 {
  font-size: 1.8 * @rem;
}
h4 {
  .helvetica-bold;
  font-size: 1.4 * @rem;
}

input {
  .helvetica-regular;
  font-size: 1.2 * @rem;
}

// PF Din Text Pro is used for the display of numerals.
.numeral {
  .pfdintextpro-regular;
}
