.appointment-confirmation {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  .main-content {
    margin: 14px 0 20px;
    text-align: center;
    @media (max-width: 641px) {
      &.noscroll-content {
        overflow: visible !important;
      }
    }
  }
  .confirmation-box, .prompt-box {
    margin: 22px 22px -10px;
    @media (min-width: 641px) {
      margin-top: 40px;
    }
  }
  .prompt-box {
    &__headline {
      margin-bottom: 0;
    }
  }
  .opt-in-number {
    margin-top: 15px;
    .number {
      font-weight: 600;
      margin-right: 20px;
      @media (min-width: 641px) {
        display: block;
        margin: 0 0 15px;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.8px;
      }
    }
    .number-change {
      color: #5679c5;
      cursor: pointer;
      text-decoration: underline;
      @media (min-width: 641px) {
        font-size: 16px;
      }
    }
  }
}

.appointment-confirmation-details {
  margin-top: 25px;
  text-align: center;
  padding: 0 22px;
  .date {
    padding: 0;
  }
  .calendar {
    float: right;
    @media @mobile {
      display: none;
    }
  }
  @media (min-width: 641px) {
    margin-top: 16px;
  }
}

.please-remember-to {color: red}

.appointment-confirmation-time,
.appointment-confirmation-date {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  @media (min-width: 641px) {
    font-size: 24px;
  }
}

.appointment-confirmation-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  padding: 0 22px;
  position: relative;
  a.ical, a.google, a.outlook, a.appointment-confirmation-get-directions {
    background-repeat:no-repeat;
    background-position: left center;
    color: #5679c5;
    padding: 7px 0 5px 35px;
  }
  a.ical    { background-image:url('~@bimages/ical.png');       }
  a.google  { background-image:url('~@bimages/googlecal.png');  }
  a.outlook { background-image:url('~@bimages/outlookcal.png'); }
  a.appointment-confirmation-get-directions { background-image:url('~@bimages/gps-nav.png'); }
  p {
    display: none;
  }
  @media (min-width: 641px) {
    margin-top: 50px;
    .action-button, .addeventatc_dropdown{
      display: none;
    }
    p {
      display: inline-block;
    }
  }
}

.facebook .appointment-confirmation{
  margin-top: 0px;
}

.appointment-confirmation-passbook {
  width: 120px;
  height: 4em;
  display: block;
  margin: 20px auto 0;
  img {
    // Image size is 240x80 for Retina (2x density)
    width: 120px;
    height: 40px;
  }
  @media (min-width: 641px) {
    margin-top: 0;
  }
}

.appointment-confirmation-netmod {
  display: none; // not sure if this is necessary
  // border: 1px solid @light-slate;
  // width: 100%;
  // overflow:hidden;
  // @media @mobile {
  //   border: none;
  // }
}

/* Calendar widget: shows date and month.
 *
 * Playing with variances of this, so while not visible by default, keeping it
 * in the branch for now.
 */
.calendar{
  .helvetica;
  font-size: 8 * @rem;
  line-height: 1.6;
	text-align:center;

  color: @body-color;
  background: @light-slate;

	width: 2em;
  .border-radius(0.1em);
}

.calendar em{
	display:block;

  font-size: 0.23em;
  font-style: normal;
  line-height: 1.95;

  color: @background-color;
  background: @blue;

  .border-bottom-right-radius(.43em);
  .border-bottom-left-radius(.43em);
}

@media (min-width:  641px) {
  main.business-schedule{
    position:relative;
    margin-top:0px;
    border-bottom:0px;

    #page > div{
      padding-top:36px;
      padding-bottom:55px;
      height:100vmin;
    }
  }
  #confirmation_container{
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  #confirmation_container .appointment-confirmation-netmod{
    height: 100%;
    flex-grow: 1;
    padding-bottom: 55px;
    border-bottom:0px;
  }
  main.business-schedule #footer,#confirmation_container #footer{
    position:absolute;
    bottom:0px;
  }
}



.appointment-confirm-fm {
  display: flex;
  flex: 1;
  flex-direction: column;
}


.appointments-timeline {
  background-color: #fff;
  color: #1d3f65;
  list-style: none;
  padding: 18px 35px 18px 30px
}

@media (max-width:767px) {
  .appointments-timeline {
    margin-left: 24px;
    padding: 18px 24px 18px 0
  }
}

.appointments-timeline .appointments-timeline-item {
  background-color: #fff;
  display: flex;
  margin: 0 0 13px
}

@media (max-width:767px) {
  .appointments-timeline .appointments-timeline-item {
    position: relative
  }
}

.appointments-timeline .appointments-timeline-item .appointment-date {
  text-align: right;
  margin: 21px 25px 0 0;
  min-width: 140px
}

.appointments-timeline .appointments-timeline-item .appointment-date .date {
  display: none
}

.appointments-timeline .appointments-timeline-item .appointment-date .date.desktop {
  display: inline;
  font-family: ProximaNova
}

@media (max-width:767px) {
  .appointments-timeline .appointments-timeline-item .appointment-date {
    position: absolute;
    z-index: 2;
    top: 14px;
    left: 31px;
    font-family: ProximaNova;
    text-align: left;
    margin: 0
  }
  .appointments-timeline .appointments-timeline-item .appointment-date div {
    display: inline
  }
  .appointments-timeline .appointments-timeline-item .appointment-date .date {
    margin-right: 5px
  }
}

@media (max-width:767px) {
  .appointments-timeline .appointments-timeline-item .appointment-date .date,
  .appointments-timeline .appointments-timeline-item .appointment-date .date.desktop,
  .appointments-timeline .appointments-timeline-item .appointment-date .date.tablet {
    display: none
  }
  .appointments-timeline .appointments-timeline-item .appointment-date .date.mobile {
    display: inline
  }
}


@media (max-width:767px) {
  .appointments-timeline {
    margin-bottom: 50px
  }
}


.circle-container {
  background-color: #000;
  position: relative;
  vertical-align: top;
}

.circle-container .circle {
  background-color: #fff;
  border: 5px solid #dbdfe3;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  z-index: 2;
  position: absolute;
  top: 23px;
  left: -12px
}

.triangle-container {
  position: relative;
  vertical-align: top;
}

.triangle-container .triangle {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 8px solid #eaeaea;
  height: 0;
  width: 0;
  left: 11px;
  position: absolute;
  top: 26px;
  z-index: 2
}


.triangle-container .triangle {
  border-right: 8px solid #babec6
}

.triangle-container .triangle div {
  width: 0;
  position: relative;
  top: -7px;
  left: 1.25px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 8px solid #fafafa
}

.line-container {
  position: relative;
  display: inline-block;
  height: 100%;
}

.line-container .line {
  border-left: 4px solid #dbdfe3;
  height: 100%;
  position: absolute;
  top: 38px;
  right: 1.5px;
  margin-top: 5px
}

.confirmed .circle-container .circle {
  border: 5px solid #4ac748
}

.confirmed .triangle-container .triangle {
  border-right: 8px solid #bdeca5
}

@media (max-width:767px) {
  .confirmed .triangle-container .triangle {
    border-right: 8px solid #babec6
  }
}

.confirmed .triangle-container .triangle div {
  border-right: 8px solid #f2fdf2
}

.unconfirmed .circle-container .circle {
  border: 5px solid #FD6962
}

.unconfirmed .triangle-container .triangle {
  border-right: 8px solid #fff4f3
}

.unconfirmed .triangle-container .triangle {
  border-right: 8px solid #babec6
}

.unconfirmed .triangle-container .triangle div {
  border-right: 8px solid #fff4f3
}

.appointment-container {
  background-color: #fafafa;
  padding: 17px 38px 16px 34px;
  margin-left: 19px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  text-align: center;
  position: relative
}

.appointment-container-wrapper {
  display: flex;
}

@media (max-width: 767px) {
  .appointment-container-wrapper {
    flex-direction: column;
  }
}

.appointment-container .appointment-header {
  display: none
}

@media (max-width:767px) {
  .appointment-container .appointment-header {
    display: block;
    height: 46px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #babec6
  }
}

@media (max-width:767px) {
  .appointment-container .consumer-name {
    margin: 6px 20px 0 12px
  }
  .appointment-container .consumer-name span:nth-child(2) {
    font-family: ProximaNova
  }
}

.appointment-container .confirmation-status {
  width: 120px;
  margin-left: 15px;
  text-align: left;
}

.appointment-container .confirmation-status .confirmation-text:before {
  left: -20px;
  top: 0;
  font-family: patient-portal;
  font-size: 11px;
  border-radius: 50%;
  height: 17px;
  width: 17px
}

.appointment-container .confirmation-status .confirmed-appointment .confirmation-text {
  color: #3bb300
}

.appointment-container .confirmation-status .confirmed-appointment .confirmation-text:before {
  content: "x";
  display: inline-block;
  position: relative;
  left: -3px;
  top: -1px;
  line-height: 12px;
  text-align: center;
  font-weight: normal;
}

@media (max-width:767px) {
  .appointment-container .confirmation-status {
    top: 50px
  }
  .appointment-container .confirmation-status.confirmed {
    right: 0
  }
}

.appointment-container .confirmation-status .unconfirmed-appointment .confirmation-text {
  color: #fd6962
}

.appointment-container .confirmation-status .unconfirmed-appointment .confirmation-text:before {
  content: "z";
  border-radius: 50%;
  border: 1px solid #F91313;
  display: inline-block;
  position: relative;
  left: -3px;
  top: -1px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  color: #F91313;
  font-weight: normal;
  font-size: 8px;
}


@media (max-width:767px) {
  .appointment-container .confirmation-status {
    top: 50px
  }
  .appointment-container .confirmation-status.confirmed {
    right: 0
  }
}

.appointment-container .confirm-and-cal {
  display: flex;
  flex-direction: column;
  width: 141px
}

@media (min-width: 767px) {
  .appointment-container .confirm-and-cal .add-to-calendar .react-add-to-calendar__wrapper:before {
    content: "n";
    font-family: patient-portal;
    left: -17px
  }
  .appointment-container .confirm-and-cal .add-to-calendar .react-add-to-calendar__mobile {
    display: none;
  }
  .appointment-container .confirm-and-cal .confirm-appointment .react-confirm-appointment__mobile {
    display: none;
  }

}

@media (max-width: 767px) {
  .appointment-container .confirm-and-cal .add-to-calendar .react-add-to-calendar__wrapper {
    display: none;
  }
  .appointment-container .confirm-span .confirm-button-wrapper {
    display: none;
  }
}

@media (max-width:767px) {
  .appointment-container .confirm-and-cal {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 41px
  }
  .appointment-container .confirm-and-cal .add-to-calendar,
  .appointment-container .confirm-and-cal .confirm-appointment {
    flex: 50%;
    line-height: 1;
    font-size: 16px;
    height: 41px;
    margin: 0;
    font-family: ProximaNova;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
  }
  .appointment-container .confirm-and-cal .add-to-calendar {
    color: #2045b9;
    background-color: #f9fbfe;
    border-top: 1px solid #e1e7ed;
    padding: 10px 0 14px
  }
  .appointment-container .confirm-and-cal .confirm-appointment {
    color: #2045b9;
    background-color: #f9fbfe;
    border-top: 1px solid #e1e7ed;
    padding: 10px 0 14px
  }
}

.appointment-container .confirm-and-cal,
.appointment-container .confirmation-status,
.appointment-container .consumer-name {
  font-family: ProximaNova
}

@media (max-width:767px) {
  .appointment-container .appointment-procedures,
  .appointment-container .consumer-name,
  .appointment-container .provider-name {
    width: 100%;
    text-align: left
  }
}

.appointment-container .appointment-label {
  display: none
}

@media (max-width:767px) {
  .appointment-container .appointment-label {
    display: block
  }
}

@media (max-width:767px) {
  .appointment-container {
    flex-direction: column;
    height: auto;
    padding: 0;
    background-color: #fafafa;
    border-color: #babec6
  }
}

.confirmed .appointment-container {
  background-color: #f2fdf2;
  border: 1px solid #bdeca5
}

.confirmed .appointment-container .appointment-header {
  background-color: #f2fdf2;
  border-bottom: 1px solid #bdeca5
}

@media (max-width:767px) {
  .confirmed .appointment-container {
    background-color: #fff;
    border-color: #babec6
  }
}

.unconfirmed .appointment-container {
  background-color: #fff4f3;
  border: 1px solid #cdcfd5
}

.unconfirmed .appointment-container .appointment-header {
  background-color: #fff4f3;
  border-bottom: 1px solid #cdcfd5
}

@media (max-width:767px) {
  .unconfirmed .appointment-container {
    background-color: #fff;
    border-color: #babec6
  }
}


.reminder-alert-text {
  padding: 20px 20px 20px 50px;
  line-height: 17px;
  position: relative;
  font-family: ProximaNova;
  color: #F91313;
  font-weight: normal;
  font-size: 12px;
  max-width: 300px;
  text-align: left;
}
.reminder-alert-text::before {
  display: block;
  position: absolute;
  left: 20px;
  top: 20px;
  content: "!";
  border-radius: 50%;
  border: 1px solid #F91313;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  color: #F91313;
  font-weight: normal;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.8);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s
}

.modal-content {
  position: fixed;
  top: 5%;
  left: 30%;
  margin: auto;
  background-color: #ffffff;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: gray;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #ffffff;
  color: black;
}

.modal-body {padding: 2px 16px;}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
.calBtn {
  background:none!important;
  color:inherit;
  border:none;
  padding:0!important;
  font: inherit;
  cursor: pointer;
}

.confBtn {
  background:none!important;
  color:inherit;
  border:none;
  padding:0!important;
  font: inherit;
  cursor: pointer;
}

@media(min-width: 767px) {
  .calBtn {
    border-bottom:1px solid #444;
  }
  .confBtn {
    border-bottom:1px solid #444;
  }
}
.reminder-alert-text {
  padding: 10px 10px 10px 20px;
  line-height: 17px;
  position: relative;
  font-family: ProximaNova;
  color: #F91313;
  font-weight: normal;
  font-size: 12px;
  max-width: 500px;
  text-align: left;
  list-style: none;
}
.reminder-alert-text::before {
  display: block;
  position: absolute;
  left: 0px;
  top: 10px;
  content: "!";
  border-radius: 50%;
  border: 1px solid #F91313;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  color: #F91313;
  font-weight: normal;
}


#text_optin{
  padding-top: 10px;
  .prompt-box {
    padding: 28px 32px;
  }
}

#text_optin_thank_you{
  padding: 10px;
  .optin-thanks confirmation-box {
    padding: 28px 32px;
  }
}