// Color scheme based on Harmony
//
// Use these for consistency. Be conservative when adding new colors.


// Base colors
@lighter-gray:    #f3f5f8;
@off-white:       #f9f9f9;
@lightest-gray:	  #eeeeee;
@light-gray:      #dddddd;
@gray:            #cccccc;
@dark-gray:       #999999;
@darker-gray:     #404040;
@darker-gray-300:   #69737D;
@lightest-slate:  #f3f8fe;
@lighter-slate:   #e3eaf3;
@light-slate:     #c0d0e4;
@slate:           #6e8ba8;
@dark-slate:      #193048;
@darker-slate:    #131f2b;
@bright-blue:	  #0098cd;
@light-blue:      #4376ef;
@blue:            #3965ce;
@dark-blue:       #2c4d9c;
@light-green:     #f6fee6;
@green:           #a5d001;
@dark-green:      #4b9a18;
@darker-green:    #025427;
@light-yellow:    #fffce5;
@yellow:          #fbe0a3;
@dark-yellow:     #f2b835;
@orange:          #e77b07;
@light-pink:      #fefcfc;
@pink:            #ef797e;
@red:             #dc3c1e;
@fuchsia:         #c72f79;
@dark-fuchsia:    #a32763;


// By context
@background-color:  #ffffff;
@body-color:        #404040;
@link-color:        @blue;
@border-color:      #cccccc;


// Default body background and foreground
html {
  background: #fafafa;
}
body {
  color: @body-color;
}
