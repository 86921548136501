.availability-yelp-main {

  //the iframe test page had a width of 958 px. Assuming this is the approximate real estate we'll have in the real page
  width: 958px;
  overflow:hidden;
  .business-card-hours p span:first-child {
    font-weight:600;
  }
  .availability-calendar-main {
    h1 {
      margin-bottom:20px;
      padding:0;
      border: none;
      box-shadow: none;
      webkit-box-shadow:none;
    }
    .availability-calendar-main {
      table {
      margin:0;
      }
      @media screen and (max-width:640px) {
        margin:0 -20px !important;
      }
    }
  }

  .clear-both{
    clear:both;
  }

  .hidden{
    display: none;
  }

  hr {
    width:100%;
    margin-left:10px;
    border:none;
    border-bottom:1px solid @light-gray;
  }

  .business{
    padding:20px 0;
    position:relative;
    background-color:@lightest-slate;
    border-bottom:1px solid @light-slate;
    @media @mobile {
      padding:20px;
    }
    .logo{
        float: left;
        margin: 0 20px 0 20px;
       .business-logo(70px, 1.0);
    }
    .heading{
      display:none;
    }
    .intuit-logo{
      position:absolute;
      top:20px;
      right:20px;
      max-width: 103px;
    }
    .name{
      padding:0;
      font-weight: bold;
      margin:0 120px 10px 0;
    }
    .main{
      width: 40%;
      float:left;
      padding-right:20px;
      .address{
        .locality{
          display:block;
        }
      }
      .contact{
        p{
          padding:0;
        }
      }
    }
    .meta{
      width: 40%;
      float:left;
      p{
        padding:0;
      }
      h3{
        padding:0;
        font-size:1em;
      }
      .insurances-header{
        padding:0;
      }
      li:before{
        content: " • ";
      }
      li:first-child:before{
        content: "";
      }
      .limit-first-3 li:nth-child(1n+4) {
        display: none;
      }
      li{
        margin:0;
        padding:0;
        display:inline;
      }
      ul{
        margin:0;
        padding:0;
      }
    }
  }
  .appointment{
    .select-time{
      display:none;
    }
    padding:20px 0;
    clear:both;
    overflow:hidden;
    .availability{
      float:left;
      width: 100%;
      padding-left:10px;
      table{
       width: 100%;
      }
    }
    form{
      padding-left: 10px;
      p{
        padding: 0 0 5px;
      }
    }
  }
  .error-message{
    color: #d34a2e;
    text-align: center;
  }

  .input-field {
    display: inline-block;
  }

  .input-field .same-row {
    float: left;
  }

  .book-appt-submit{
    .button.primary;
    .helvetica;
    border-radius:2px;
    outline:none;
    height: 2em;
    line-height: 2em;
    text-align: center;
    display: block;
    &:hover {
      text-decoration: none;
    }
  }

  .input-title {
    font-family:"HelveticaNeueBold",
    "HelveticaNeue-Bold","Helvetica Neue Bold","HelveticaNeue","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-weight:600;
    font-stretch:normal;
    display:block;
    padding-bottom:5px;
  }
  .form-row {
    display:block;
    margin:0 10px 10px;
    clear: both;
  }
  .input-metadata {
     padding-left: 2px;
    font-style:italic
  }
  .input-long {
    width: 519px;
    height:100px;
    font-size:14px;
    &:focus {
        border-color:@blue;
    }
  }

  @media @mobile {
    width:100%;
    float:none;
    .business {
      .logo,.main,.meta {
        display:none;
      }
      .heading {
        display:block;
        margin-left:10px;
        font-size: 22px;
        line-height: 1.1;
        font-weight:200;
      }
    }
    .appointment {
      .booking-form {
        display:none;
      }
      .select-time {
        display:block;
        margin-left:10px;
        font-size: 22px;
        line-height: 1.1;
        color: @dark-gray;
        font-weight:200;
      }
    }
    .availability-error {
      display:none;
    }
  }
}
