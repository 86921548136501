// Full width network module is 960px wide, with gutter and spacing at 20px,
// that leaves 290px for each card.
//
// Mobile and narrow modules have cards are stacked on top of each other.
.network-module {
  .helvetica; // font size and line height

  width: 960px;
  margin: 7px auto;
  clear: both;

  &.narrow {
    width: 100%;
    padding: 0 20px;
    h2 {
      text-align: left !important;
      margin-left: 0 !important;
    }
    .network-card, .network-card-premium {
      width: 100% !important;
      margin: 0 0 20px 0 !important;
    }
  }
  @media @mobile {
    .narrow;
  }


  h2 {
    .helvetica-regular;
    padding:26px 0 18px 0;
    text-align: center;

    b {
      .helvetica-bold;
    }

    // Down arrows between header and list of businesses
    & + img {
      display: block;
      width: 13px;
      float: right;
      margin-top: -37px;
      border: none;
      @media @mobile {
        display: none;
      }
    }
  }

  .network-card, .network-card-premium {
    margin: 0 0 20px 20px;
    float:left;
  }
}


// Styling for network card.
//
// A regular network card is 280 x 110.  A premium network card is 280 x 210
// (4:3)
//
// Network card consists of a header with the vertical name, a logo of the
// business, the business name, and the rating (stars and number of reviews).
// This is slightly different from business card, where vertical shows between
// name and rating.
//
// Premium network card has addition 100px of height, which is used to show one
// of the following:
// - Currently active coupon, same styling as business card, or
// - Featured review (first 3 lines, link to read more), or
// - Satisfaction score if more than 80, or
// - Description (first 3 lines, link to read more), or
// - Emptyness
.network-card {
  width: 280px;
  height: 110px;
  border: 1px solid @light-slate;
  position: relative;
  overflow: hidden;
  margin: 0;
  .helvetica; // font size and line height
}
.network-card-vertical {
  background-color: @lighter-slate;
  padding: 5px 20px;
  .overflow-ellipsis;
}
.network-card-logo {
  display: block;
  float: left;
  margin: 10px 10px 0 20px;
  .business-logo(48px, 1.0);
  &:before {
    width: auto;
  }
}
.network-card-name {
  padding: 10px 20px 10px 0;
  display: block;
  .overflow-ellipsis;
}
.network-card-rating {
  font-size: 1.3 * @rem;
  padding: 0;
  margin-right: 20px;
  line-height: 1;
  color: @body-color;

  span {
    vertical-align: middle;
  }
}


// Premium network card should use this style instead of network-card.
.network-card-premium {
  .network-card;
  height: 180px;
}
.network-card-premium-section {
  margin: 20px 0px;
  overflow: hidden;
}

// When premium network card shows review or description, if uses a blockquote
// element to show partial (up to 3 lines) quote, followed by a read more link.
.network-card-quote {
  padding: 10px 20px;
  max-height: 48px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size:0.8em;
  .helvetica-italic;
  &:before {
    content: "“";
    float: left;
    display: block;
    margin: 4px 0 0 -20px;
    font-size: @rem * 4;
    color: @light-slate;
  }
}
.network-card-read-more {
  padding: 0 20px;
  font-size:0.8em;
  display: block;
  text-align: right;
}

// When premium network card shows active coupon, it uses this style (similar to
// business card).
.network-card-coupon {
  margin: 0px 20px;
  max-height: 80px;
  // Same spacing as regular P.
  text-align: center;
  padding: 10px 20px !important;

  background-color: @light-yellow;
  // Same as Harmony button: hover over card adds drop shadow.
  border: 2px dashed @dark-yellow;
  border-radius:3px;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  //&:hover, &:active { .box-shadow(0 2px 0 rgba(2, 84, 39, 0.3), inset 0 0 0 2px rgba(255, 255, 255, 1.0));}

  // Need dark color for contrast.
  a {
    color: @orange;
    text-decoration: none;
  }
  // Expiration in smaller text, on its own line.
  .network-card-coupon-expires {
    font-size: 55%;
    display: block;
    text-transform: uppercase;
  }
}

// When premium network card shows satisfaction score, it uses this element with
// two spans, first one contains the satisfaction score, second one contains the
// title ("would refer ...").
.network-card-satisfaction {
  padding:10px 20px;
}
.network-card-satisfaction-score {
  .pfdintextpro-bold;
  font-size: 3.6 * @rem;
  line-height: 0.9;
  display: block;
  color: @dark-green;
  float: left;
  margin-right: 10px;

  & + span {
    font-size: 1.2 * @rem;
    line-height:1.3em;
    text-transform: uppercase;
    display: block;
  }
}
