.thank-you-page {
  width: 100%;

  h2.thank-you-page-content-message {
    margin: 0 40px 0;
    .helvetica-regular;
  }

  .network-module {
    margin: 0 auto;
  }

  .no-network-module {
    padding: 20px;
    max-width: 640px;
    margin-top: 10%;
    margin-bottom: 10%;
  }

}
