// Derived from Harmony layout.
//
// Scrollable and nav not included, they're specific to app.
//
// Header not included, IE 8 struggles with non-HTML5 elements.
header {
  width: 100%;
  background-color: @dark-slate;
  color: white;
  overflow:hidden;
  z-index:10;
  position:fixed;
  top:0;
  max-height:38px;
  min-width:320px;
  overflow:hidden;
  white-space:nowrap;

  .menu {
    float: left;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-box-shadow: inset -1px 0 rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset -1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset -1px 0 rgba(255, 255, 255, 0.1);
    border-right: 1px solid black;
    background: url('~@bimages/icons-s2b11c90dfb.png') 0 -1771px no-repeat;
    white-space: nowrap;
    margin-right: 15px;
  }

  .title {
    color: @lighter-slate;
    float: left;
    font-size: 1.5rem;
    margin: 10px 20px;
  }
}

main {
  height: auto;
  margin-top:36px;
  iframe {
    height: 100%;
    width: 100%;
  }

  #page {
    //height: 100%;
    overflow: auto;
  }
}

.call-us-button {
  position: absolute;
  right: 13px;
  top: 7px;
  z-index: 11;
  width: 73px;
  height: 25px;
  line-height: 25px;
  background: #1f559b;
  text-align: center;
  border-radius: 3px;
  display: none;
  @media @mobile {
    display: inline-block;
  }
}

.call-us-button a {
  text-decoration: none;
  font-size: 12px;
  color: #fff !important;
}

.masthead {
  width: 100%;
  background-color: @lighter-gray;
  border-top: 1px solid @lighter-gray;
  border-bottom: 1px solid #E6E8EA;
  margin: 0;
  padding: 0;

  div {
    position: relative;
    min-width: 320px;
    max-width: 960px;
    padding: 24px 20px;
    line-height:normal;
    h1 {
      .helvetica-light;
      font-size: 3 * @rem;
      .box-shadow(none);
      border: none;
      padding: 0;
      color:@slate;
      @media @mobile {
          font-size:2.3 * @rem;
      }
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  // not sure why harmony thinks this is a good idea
  // min-width: 790px;
  max-width: 1270px;
}
// Commented out by Brennan 9/23/14.  The top level nature of this was confusing and giving me some trouble with facebook
// Everyone that uses an h1 might not need a bottom border
// h1 {
//   .box-shadow(inset 0 -1px @lighter-slate);
//   border-bottom: 4px solid @lighter-gray;
//   padding: 24px 0;
//   margin: 0;
// }
h2,
h3 {
  padding: 24px 0;
}
h4 {
  padding: 12px 0 3px;
}
p {
  padding: 0 0 20px;
}
ul {
  list-style: disc;
  margin-left: 20px;
  li {
    padding: 0;
  }
}
