
.over-max-width{
  max-width:100%;
  background:#ffffff;

  .email-pre-status{
    position: fixed;
    width: 215px;
    left: 50%;
    margin-left: -110px;
    height: 35px;
    border-radius: 60px;
    top:20px;
    z-index: 12;
    line-height: 18px;
    span{
      display: block;
      color:#fff;
      line-height: 32px;
      padding-left:40px;
      font-size:13px;
    }
    &:before{
      position: absolute;
      left:40px;
      top:6px;
    }
  }

  .email-pre-error{
    background-color: #f4584c;
    box-shadow: 0 2px 0 0 rgba(255, 125, 125, 0.25);
    &:after{
      content: "i";
      display: block;
      width: 18px;
      height:18px;
      font-family: "Arial";
      border-radius: 10px;
      text-align: center;
      font-weight: bold;
      margin-left: 15px;
      top: 8px;
      position: absolute;
      color:#f4584c;
      background: #d2392d;
    }

  }


  .email-pre-success{
    width: 160px;
    margin-left: -80px;
    background-color: #08b604;
    box-shadow: 0 2px 0 0 rgba(83, 173, 92, 0.25);

    &:after{
      content: "✓";
      color: #068c03;
      font-weight: bold;
      font-size: 18px;
      top:7px;
      left:15px;
      position: absolute;
    }
  }

}
.business-preference-main {
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: table;
  color: #1d3f65;
  background: #ffffff;

  .business-profile-header {
    border-bottom: solid 1px #e1e7ed;
    .title {
      font-size: 18px;
      font-weight: bold;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
      text-transform: capitalize;
      background-image: linear-gradient(98deg, #005dbb, #4428b8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  aside {
    width: 15%;
    background-image: linear-gradient(#1d295a, #23488d);
    display: table-cell;
    padding: 0 16px;
    min-width: 200px;
    vertical-align: top;
    &.hiddenAside{
      min-width:65px;
      width:65px;
      .sidebar-item-label{
        display: none
      }
    }
    .nav {
      width: 30px;
      height: 30px;
      background: url('~@bimages/settingBar.png') 0 top no-repeat;
      display: inline-block;
      margin: 20px 0 30px 0;
    }
    ul {
      margin: 0;
      list-style: none;
      color: #fff;
      font-size: 16px;
      line-height: 30px;
      display: table;
      li {
        margin: 0;
        list-style: none;

        span {
          display: table-cell;
          line-height: 30px;

          &.sidebar-setting-icon {
            width: 35px;
            background: url('~@bimages/settingBar.png') 0 -30px no-repeat;
          }
        }
      }

    }
  }

  .main-content {
    background: #fff;
    margin: 0;

    .main-title {
      height: 60px;
      background-color: #f9fafc;
      font-size: 18px;
      text-align: left;
      color: #1d3f65;
      line-height: 50px;
      padding-left: 30px;
      border-top: solid 3px #f4f6f8;
      border-bottom: solid 1px #f4f6f8;
    }

    .subtitle {
      border-bottom: solid 1px #e1e7ed;
      font-size: 16px;
      padding-left: 35%;
      color: #2045b9;
      text-align: center;
      height: 50px;
      line-height: 50px;

      span {
        background: url('~@bimages/settingBar.png') -4px -55px no-repeat;
        padding-left: 28px;
        display: block;
        border-bottom: 1px solid #1f45ba;
        max-width: 200px;
        height: 50px;
      }
    }
  }
  &.business-preference-main-for-hop{
    #postcard-section{
      display: none;
    }
    .error-message{
      color: #CF2626;
      display: none;
      padding-left: 5px;
    }
    .disable-section-box{
      input[type=checkbox]{
        background-position: 0 -1273px;
      }
      .category-content{
        color: #a5b2c1;
      }
    }
    input.w1{
      color: inherit;
    }
  }
  section {
    border-bottom: solid 1px #e6ebf0;
    display: table;
    padding: 50px 0;
    width: 100%;
    color: #1d3f65;
    .category-title {
      font-size: 18px;
      text-align: left;
      color: #58799f;
      width: 35%;
      min-width: 200px;
      display: table-cell;
      span {
        margin-left: 10%;
      }
    }

    .category-content {
      display: table-cell;
      .item-name {
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: #7d808e;
        display: none;
      }
    }
  }

  input.w1 {
    width: 300px;
    margin: 10px 0 30px 0;
    height: 34px;
    font-size: 14px;
    text-align: left;
    color: #cfd7e6;
    border-radius: 5px;
    background-color: rgba(235, 236, 239, 0.3);
    box-shadow: inset 0 1px 3px 0 rgba(239, 239, 241, 0.7);
    border: solid 1px #cfd7e6;
  }
  //comment this out since the consumer contact infor is readonly
  //input[type=text]:focus {
  //  box-shadow: none;
  //  font-size: 16px;
  //  color: #25292d;
  //  color: #cfd7e6;
  //  background-color: #ffffff;
  //  box-shadow: 0 2px 3px 0 rgba(58, 83, 119, 0.05);
  //  border: solid 1px #cbcbd2;
  //  border-radius: 5px;
  //}

  .ep-switch {
    position: relative;
    float: left;
    width: 60px;
    margin: 0 18px 0 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    input[type="checkbox"] {
      display: none;
    }
  }

  label.ep-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    width: 60px;
    height: 25px;
    border-radius: 5px;
  }

  input[type="checkbox"] + label {
    padding-left: 0;
  }
  .ep-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .ep-switch-inner::before, .ep-switch-inner::after {
    display: block;
    float: right;
    width: 50%;
    height: 25px;
    padding: 0;
    line-height: 25px;
    font-size: 11px;
    color: white;
    font-family: "Helvetica Neue";
    box-sizing: border-box;
  }

  .ep-switch-inner::after {
    content: attr(data-on);
    padding-left: 7px;
    background-image: linear-gradient(138deg, #0089ff, #2d34e5);
    color: #FFFFFF;
  }

  .ep-switch-inner::before {
    content: attr(data-off);
    padding-right: 7px;
    background-color: #bdcad9;
    text-align: right;
  }

  .ep-switch-bar {
    width: 24px;
    height: 20px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(63, 74, 90, 0.34);
    position: absolute;
    display: block;
    margin: 2px 2px 0 1px;
    top: 0;
    bottom: 0;
    right: 32px;
    transition: all 0.3s ease-in 0s;
  }

  .ep-switch-checkbox:checked + .ep-switch-label .ep-switch-inner {
    margin-left: 0;
  }

  .ep-switch-checkbox:checked + .ep-switch-label .ep-switch-bar {
    right: 0px;
  }
  .ep-switch-checkbox:disabled + .ep-switch-label .ep-switch-inner::after {
    background: #bdcad9;
  }
  .item-detail {
    border-bottom: solid 1px #eaedf1;
    padding: 9px;
    line-height: 25px;
    &.disabled {
      background:#f1f4f8;
      color:#a5b2c1;
      label.ep-switch-label{
        cursor: not-allowed;
      }
    }
    .status-off {
      color: #1d3f65
    }
  }
  .note-text {
    border-bottom: solid 1px #eaedf1;
    line-height: 15px;
    padding: 10px 0;
    color: #626972;
    font-size: 12px;
  }

  .inline{
    padding-top:15px;
    display:table;
    span{
      padding-left:10px;
      display: table-cell;
    }
  }

  input[type="radio"] {
    display: inline-block;
  }
  .margin_1 {
    margin: 28px 0 10px 0;
  }
  .margin_2 {
    margin-left: 40px;
  }
  .gray-out {
    color: #25292d;
  }

  @media all and (max-width: 500px) {
    aside {
      display: none !important;
    }

    .business-profile-header {
      background-image: linear-gradient(263deg, rgba(48, 35, 174, 0), rgba(48, 36, 174, 0.01), rgba(46, 130, 197, 0.64)), linear-gradient(98deg, #073cb8, #060051), linear-gradient(#2f405b, #2f405b);
      box-shadow: 2px 0 0 0 rgba(20, 40, 54, 0.1);
    }

    .business-profile-header .title {
      color: #fff;
      font-size: 16px;
      -webkit-text-fill-color: #fff;
      font-weight: normal;
    }

    .subtitle {
      padding-left: 0 !important;
    }

    .subtitle span {
      margin: 0 auto !important;
    }

    .main-content .main-title {
      padding-left: 20px;
      height: 48px;
      line-height: 38px;
    }

    .category-title, .category-content {
      display: block !important;
    }

    .category-content {
      padding: 0 20px !important;
    }

    .category-title span {
      margin-left: 0 !important;
      font-size: 20px !important;
    }

    .category-title {
      margin: 0 0 25px 20px !important;
    }

    section {
      padding: 30px 0 !important;
    }

    .ep-switch {
      float: right !important;
    }

    input.w1 {
      height: 44px;
      width: 100%;
      font-size: 16px;
    }

    .width-w2 {
      width: 500px !important;
    }

    .ep-switch {
      margin: 0 !important;
    }
  }

}