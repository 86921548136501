// All pages share the same footer.


// Page footer consists of search form and several site links (DF, ToS, etc).
footer {
  clear: both;
  position: relative;

  // These styles come straight out of Harmony.
  height: 55px;
  background-color: #131f2b;
  line-height: 38px;

  // On mobile search form and links are stacked, adjust height accordingly.
  @media @mobile {
    height: auto;
    text-align:center;
  }

  // Search form.
  //
  // This comes first in document order and stacked above links in phone view,
  // but in full view goes on right side of footer.
  #footer-search-form {
    position: absolute;
    top: 10px;
    right: 20px;
    @media @mobile {
      position: static;
      padding: 10px 20px;
    }

    color: @background-color;
    label {
      vertical-align: middle;
      margin-right: 4px;
      @media @mobile {
        display: none;
      }
    }
    input {
      vertical-align: middle;
      width: 180px;
      // Harmony search field has a looking glass in the background.
      // We hide this to make room for the Mic on Chrome.
      background: white;
    }
    button {
      .button;
      .dark .button;
      vertical-align: middle;
    }
  }

  // Links to main site, about, Tos, etc.
  //
  // These come second in document order and stacked below search form in phone
  // view, but in full view we put them on the left.
  .footer-links {
    position: absolute;
    top: 10px;
    left: 20px;
    @media @mobile {
      position: static;
      padding: 10px 20px;
    }

    // Line height of 34px aligns link and DF logo.
    a {
      vertical-align: middle;
      margin-right: 6px;
      color: #bbb;
      &:hover {
        color: @light-blue;
        text-decoration:none;
      }
    }
    a + a + a {
      border-left: 1px solid @dark-slate;
      padding-left: 8px;

    }
    img {
      max-height:28px;
      max-width: 150px;
      vertical-align: sub;
    }
    // In phone view, logo is on one line, links below.
    @media @mobile {
      a:first-child {
        display: block;
      }
    }
  }
}
