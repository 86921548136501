#fs-embed1 {
    margin-left:10px;
    .fs-booking-choices {
        li { margin-bottom:10px;
        ul li a, ul li .fs-service-price { font-size:16px; }
        }
    }
    th,td,tr { background:none;
        &:hover { background:none; }
    }
    .fs-openings tr:first-child th {
        font-size:14px;
        text-align:center;
        font-weight:300;
        padding:5px;
        span { font-weight:500; }
    }
    .fs-booking-state, .fs-booking-fields {
        th {
            border:none;
            box-shadow:none;
        }
        td {
            border:none;
            height:auto;
            vertical-align:top;
        }
    }
    .fs-booking-state {
        th {
            text-transform:none;
            font-weight:600;
            color:#404040;
        }
    }
    #fs-main p {
        display:none !important;
    }
    .fs-booking-fields {
        margin-top:20px;
        tr th {
            color:#404040;
            text-transform:none;
            text-align:left;
            vertical-align:top;
            padding:5px 10px 5px 0;
            .fs-instructions { display:none; }
        }
    }
    .fs-required-mark-note, .fs-controls, .fs-footer { text-align:left; }
    .fs-service-opening {
        padding:4px;
        border-radius:3px;
        margin:2px;
        &:hover {
            background-color:#e3eaf3;
            text-decoration:none;
        }
    }
    input { outline:none; }
    input[type=submit] {
        border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        color: #fff;
        font-size: 16px;
        border: none;
        outline: none;
        background-size: 100%;
        background-color: #3558c1;
        background-image: -moz-linear-gradient(top, #4376cf, #3558c1);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4376cf), to(#3558c1));
        background-image: -webkit-linear-gradient(top, #4376cf, #3558c1);
        background-image: linear-gradient(to bottom, #4376cf, #3558c1);
    }
}
