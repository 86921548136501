// Refer a friend styling.
//
// We have two different views.  On desktop we show Facebook Send dialog as
// primary action, links to share email in a variety of Webmail apps, and links
// to share on Twitter, Facebook and leave a review on Google+.
//
// Facebook Send doesn't work on mobile/iPad, so that is hidden, and the primary
// action is to share by email.  Since user likely to use Mail app, we offer
// native sharing only.  Twitter and Facebook sharing also available, but
// sharing reviews on Google+ is not.
main.refer-page ~ #footer{
  @media  (min-width:  641px)  {
    position:absolute;
    bottom:0px;
  }
}
main.refer-page {
  .helvetica;
  @media  (min-width: 641px)  {
    margin-top:0px;
    position:relative;
    border-bottom: 0px;

    // Refer using Facebook send dialog, only on desktop
    #page{
      height: 100vmin;
    }
    #page > div{
      padding-top:36px;
    }
  }
  .refer-page-desktop {
    margin-top: 40px;
    padding: 0 20px;

    p {
      padding: 20px 0;
      text-align: center;
    }

    .facebook-share {
      .button;
      .button.primary;
      .social-icon("~@bimages/facebook-24.png");
    }

    .refer-gmail {
      .social-icon("~@bimages/email-24-black.png");
      .button;
      .button.secondary;
    }

    .refer-yahoo {
      .social-icon("~@bimages/yahoo-24-black.png");
      .button;
      .button.secondary;
    }
    .refer-outlook {
      .social-icon("~@bimages/outlook-24-black.png");
      .button;
      .button.secondary;
    }
    .refer-email-native {
      .social-icon("~@bimages/email-24-black.png");
      .button;
      .button.secondary;
    }

    // This link works only on desktop
    .googleplus-review {
      .social-icon("~@bimages/googleplus-24-black.png");
      .button;
      .button.secondary;
      display: inline-block;
    }

    @media @mobile, @ipad {
      display: none;
    }
  }

  // Form for referring via email, only on desktop
  .refer-page-email {
    padding: 0 20px;
    margin: 40px 0;
    @media @mobile, @ipad {
      display: none;
    }

  }

  // Email link shows only on mobile
  .refer-page-mobile {
    margin: 40px 0 20px;
    padding: 0 20px;
    text-align: center;

    display: none;
    @media @mobile, @ipad {
      display: block;
    }

    .facebook-share {
      .button;
      .button.primary;
      .social-icon("~@bimages/facebook-24.png");
    }

    .refer-email-native, .refer-email-demo {
      .button;
      .button.secondary;
      .social-icon("~@bimages/email-24-black.png");
      display: inline-block;
    }
  }


  // Share on social networks.  These options are available in both desktop and
  // mobile views.
  .refer-page-social {
    padding: 0 20px;
    margin-bottom: 40px;

    // These two links work on desktop and mobile
    .facebook-share {
      .social-icon("~@bimages/facebook-24-black.png");
    }
    .twitter-share {
      .social-icon("~@bimages/twitter-24-black.png");
    }

    @media @mobile, @ipad {
      display: none;
    }
  }

  // These are used to style the button with a simple-icons 24x24 icon.
  // Prefix the button name with an <i> element.
  .social-icon(@url) {
    i {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin: -2px 4px 0 -12px;
      vertical-align: middle;
      background: url(@url) no-repeat;
    }
    &:active i {
      .opacity(0.5);
    }
  }
}
