.appointment-details {
  background: white;
  .please-remember-to {
    .reminder-alert-text {
      max-width: none;
    }
  }

  .appointments-list {
    border: 1px solid #D5DBE2;
    border-radius: 10px;
    width: 386px;
    padding: 0 20px 0 20px;
    margin: 30px auto;
    .appointment-username {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #69737D;
      margin-top: 30px;
      margin-bottom: 25px;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
      }
    }
    .appointment-wrapper {
      border-bottom: 1px solid #F2F2F2;
      padding-bottom: 25px;
    }
    .appointment-datetime {
      font-size: 18px;
      color: #264BCF;
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
    }
    .appointment-message {
      font-size: 14px;
      color: #69737D;
      padding: 8px 0 5px 0;
    }
    .appointment-actions {
      display: flex;
      justify-content: space-between;
      a.ical, a.google, a.outlook {
        width: 108px;
        height: 32px;
        border: 1px solid #D5DBE2;
        border-radius: 3px;
        display: flex;
        background-repeat: no-repeat;
        background-position: left center;
        background-position-x: 15px;
        font-size: 14px;
        color: #1B2126;
        padding: 7px 20px 5px 36px;
        text-decoration : none;
      }
      a:hover {
        background-color: #f3f6ff;
        border-color: #264bcf;
      }
      a.ical    { background-image:url('~@bimages/appointments-iCalendar.svg');       }
      a.google  { background-image:url('~@bimages/appointments-google-calendar.svg');  }
      a.outlook { background-image:url('~@bimages/appointments-outlook-calendar.png'); }
    }
  }


  .appointment-confirmation-actions {
    padding: 20px;
    a.ical, a.google, a.outlook {
      display: inline-block;
      white-space: nowrap;
      justify-content: space-between;
    }
    @media (max-width: 920px) {
      flex-wrap: wrap;
      a.ical, a.google, a.outlook {
        flex: 0 1 100%;
      }
    }
  }
}
