// Use this when styling the logo of a business (e.g business card, network
// module).
//
// Apply to container element (div/a) containing the actual logo (img).  The
// logo size is set in the parameters.  The container adds a border and will be
// 8px wider/taller than the image.
//
// width  - The width of the image in pixels (default to 48px)
// apsect - The aspect ratio (width/height), e.g. 1.0 for square, 3/2 for 60x40
//          (default to 1.0)
.business-logo(@width: 48px, @aspect: 1.0) {
  @height: @width / @aspect;
  max-width: @width + 10;
  max-height: @height + 10;
  background: white;

  // These styles are based on the Harmony avatar class.
  .border-radius(3px);
  display: inline-block;
  position: relative;
  border: 1px solid @border-color;
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    .background-clip(padding);
    .border-radius(2px);
    .box-shadow(inset 0 0 0 4px white);
    overflow: hidden;
    z-index: 2;
  }
  &:hover {
    border-color: @border-color;
  }
  img {
    .background-clip(padding);
    .border-radius(2px);
    display: block;
  }

  // Actual image may be of different size, so we scale it down to fit the
  // allotted height and crop.  Non-square images will show off center.
  overflow: hidden;
  img {
    max-height: @height;
    margin: 4px;
    max-width: @width;
  }
}


// Use this for text blocks that you want to truncate with an ellipsis.
.overflow-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


// Requires no introduction.
.clearfix(){
  &:after {
    content: "*";
    display: block;
    visibility: hidden;
    clear: both;
    line-height: 0;
    height: 0;
  }
}

// A favorite ribbon hanging on the right side.  See feature review for example.
//
// content    - Text content (e.g. "featured)
// color      - Text color
// background - Background (ribbon) color
.favorite-ribbon(@content, @color, @background) {
  position: relative;
  &:after {
    content: @content;
    color: @color;

    position: absolute;
    height: 0px;
    line-height: 0px;
    padding: 0px 0px 0px 10px;
    top: 4px;
    right: 0px;

    border-width: 12px 12px;
    border-style: solid;
    border-color: @background @background @background transparent;

    .box-shadow(2px 2px 2px 0px #888);
  }
}


// The stars helper uses these classes to show 0 to 5 stars (including half
// stars).

.stars-0 {
  .stars(0);
}
.stars-1 {
  .stars(1);
}
.stars-1-5 {
  .stars(1.5);
}
.stars-2 {
  .stars(2);
}
.stars-2-5 {
  .stars(2.5);
}
.stars-3 {
  .stars(3);
}
.stars-3-5 {
  .stars(3.5);
}
.stars-4 {
  .stars(4);
}
.stars-4-5 {
  .stars(4.5);
}
.stars-5 {
  .stars(5);
}

.stars(@stars) {
  display: inline-block;
  vertical-align: center;
  position: relative;
  width: 80px;
  height: 15px;
  background: url("~@bimages/rating.png") 0px 16px;
  overflow: hidden;
  text-indent: -100px;

  i {
    display: block;
    position: absolute;
    top: 0;
    width: 16px * @stars;
    height: 16px;
    background: url("~@bimages/rating.png");
  }
}  
