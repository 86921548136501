// Styling for the network map iframe inside the business portal.

body.network-map {
  margin: 0;
  padding: 0;

  .gm-style {
    // .gm-style overrides many styles, so we need to restore those.
    .network-card {
      border: none;
      padding: 5px 0;
    }
    .network-card-vertical {
      padding: 5px;
      .helvetica;
      .helvetica-regular;
    }
    .network-card-logo {
      margin: 10px 10px 0 0;
    }
    .network-card-name {
      padding: 10px 0px;
      .helvetica;
      .helvetica-regular;
    }
    .network-card-rating {
      .helvetica;
      .helvetica-regular;
    }
  }

  .ll-style {
    .leaflet-container a.leaflet-popup-close-button {
      z-index: 1;
    }
    .leaflet-popup-content-wrapper {
      border-radius: 0;

    }
    .leaflet-popup-content {
      margin: 2px 10px 5px;

    }
    // .gm-style overrides many styles, so we need to restore those.
    .network-card {
      border: none;
      padding: 5px 0;
    }
    .network-card-vertical {
      padding: 5px;
      margin-top: 0;
      margin-bottom: 0;
      .helvetica;
      .helvetica-regular;
    }
    .network-card-logo {
      margin: 10px 10px 0 0;
    }
    .network-card-name {
      padding: 10px 0px;
      margin-top: 0;
      margin-bottom: 0;
      .helvetica;
      .helvetica-regular;
    }
    .network-card-rating {
      margin-top: 0;
      margin-bottom: 0;
      .helvetica;
      .helvetica-regular;
    }
  }
}
