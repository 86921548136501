.waitlist-popup{
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  width: 510px;
  height: 310px;
  position: fixed;
  left: 50%; 
  top: 50%;
  margin-left: -255px;
  margin-top: -155px;
  z-index: 4;
  span{
    color: #BEC7CC;
    font-size: 26px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  .greentick{
    background: url('~@bimages/greentick.png') no-repeat;
    width: 50px;
    height: 50px;
    margin-left: 232px;
    margin-top: 64px;
  }
  .message{
    color: #627592;
    font-family: ProximaNova;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 510px;
    text-align: center;
    margin-top: 24px;
    padding: 0 49px;
  }
  .line{
    border: 1px solid #EEF2F7;
    margin-top: 53px;
  }
  input[type=button] {
    background: #1AA4FD !important;
    border-radius: 4px;
    width: 76px;
    height: 34px;
    color: #fff;
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 0;
    outline: none
  }
}
.waitlist-dark-mask{
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  position: fixed; 
  left: 0px; 
  top: 0px; 
  width: 100%; 
  height: 100%;
}