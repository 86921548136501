#reviews-widget{

  margin: 0 0 20px;
  padding: 0px;

  .customer-name{
    font-weight: bold;
  }
  .widgets-reviews-header{
    width: 100%;
  }
  .review-text{
    font-size:18px;
  }
  .reviewDate{
    color: #999999;
    font-weight: 400;
    font-stretch: normal;
  }
  .satisfaction-score{
    font-size: 26px;
  }
}
.iframe-width {
  width: 100%;
  background-color: #FFFFFF;

}
.facebook-reviews.widgets-reviews-header{
  padding: 20px 40px 0 40px;
  display: inline-block;
  width: 100%;
}
.facebook-reviews.widgets-reviews-header:after {
  display: table;
  content: '';
  clear: both;
}

.facebook-reviews .business-rating{
  margin: 0 0 0px 0;
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  float: left;
}

.request-appointment-container{

  float: right;
  text-align: center;
    display: inline-block;
  .blue-button-mixin();
  margin-top: 10px;
}
.request-appointment-container .schedule-form-submit {
  -webkit-border-radius:3px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  padding: 8px 8px;
  background-size: 100%;
  background-color: #3558c1;
}
.outerBox{
  margin: 0 auto;

}
.headers{
  margin: 0 0 0 20px;
  h1{
    width: 690px;
    color: #026698;
    font: bold 22px/35px Helvetica, Arial, sans-serif;
  }
  h3{
    width: 692px;
    color: #565656;
    font: bold 17px/17px Helvetica, Arial, sans-serif;
    padding: 0px;

  }
}

.request-appointment-container .schedule-form-submit {
  -webkit-border-radius: 3px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  padding: 8px 8px;
  background-size: 100%;
  background-color: #3558c1;
}
body{
  background-color: #FAFAFA;
}

.Title{
  margin: 10px 0 20px 0;

}
.addBorder{
  margin: 0 20px;
  border: 1px solid  #66A3CF;
}

.hide-display{
  display: none;
}